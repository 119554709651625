import React, { useEffect, useState } from "react";
import {
  CellRendererParams,
  Entity,
  EntityPreview,
  EntityReference,
  PropertyPreview,
  ReferencePreview,
  TableColumn,
  VirtualTable,
} from "firecms";
import {
  Employee,
  EmployeeRecord,
  employeesRecordsSubCollection,
} from "../../collections/employees"; // Import your employee schema
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { Vacancy, vacancyCollection } from "../../collections/vacancies";
import { EntityByReferencePreview } from "./EntityByReferencePreview";
import { previewVacancyColleciton } from "./filter-collections";
import { TableReferenceField } from "../../../../../lib/src/core/components/EntityCollectionTable/fields/TableReferenceField";

interface RecordsTableProps {
  selectedVacancies: Entity<Vacancy>[];
  data: RecordWithEmployee[];
  onRecordChanged: (
    record: Record<string, any>,
    employee: Entity<Employee>
  ) => void;
}
interface RecordWithEmployee {
  employee: Entity<Employee>; // Assuming you have an Employee schema
  vacancy: EntityReference; // Assuming you have an Employee schema
  record: Record<string, any>; // Adjust this based on your schema
}

const RecordsTableView: React.FC<RecordsTableProps> = ({
  data,
  onRecordChanged,
  selectedVacancies,
}) => {
  const columns: TableColumn[] = [
    {
      headerAlign: "left",
      key: "index",
      width: 100,
      title: "Номер",
    },
    {
      headerAlign: "right",
      key: "displayName",
      width: 150,
      title: "Соискатель",
    },
    {
      headerAlign: "right",
      key: "date",
      width: 150,
      title: "Дата",
      sortable: true,
    },
    {
      headerAlign: "left",
      key: "reviewed",
      width: 100,
      title: "Проверен",
    },
    {
      headerAlign: "left",
      key: "paid",
      width: 100,
      title: "Оплачен",
    },
    {
      headerAlign: "center",
      key: "plan",
      width: 100,
      title: "План",
    },
    {
      headerAlign: "right",
      key: "hours",
      width: 100,
      title: "Часов",
    },
    {
      headerAlign: "right",
      key: "rateHour",
      width: 100,
      title: "Ставка",
    },
    {
      headerAlign: "right",
      key: "modifiers",
      width: 100,
      title: "Премии и вычеты",
    },
    {
      headerAlign: "right",
      key: "sum",
      width: 100,
      title: "Сумма",
    },
    {
      headerAlign: "left",
      key: "vacancy_name",
      width: 150,
      title: "Вакансия",
    },
    {
      headerAlign: "left",
      key: "record",
      width: 100,
      title: "Запись",
    },
    // Add more columns as needed
  ];

  const [recordOpen, setRecordOpen] = useState<string | undefined>(undefined);
  const onDialogClose = () => {
    setRecordOpen(undefined);
  };

  const cellRenderer = (params: CellRendererParams<RecordWithEmployee>) => {
    if (params.column.key == "displayName") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <ReferencePreview
            size="tiny"
            previewProperties={["firstName", "familyName", "phoneNumber"]}
            reference={params.rowData?.employee.values.workerProfile!}
          />
        </Grid>
      );
    } else if (params.column.key == "date") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <Typography
            textAlign={"right"}
            paddingRight={"20px"}
            key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          >
            {(params.rowData?.record.date as Date).toLocaleDateString("ru")}
          </Typography>
        </Grid>
      );
    } else if (params.column.key == "reviewed") {
      return (
        <Grid
          justifyContent={"center"}
          alignItems={"center"}
          alignSelf={"center"}
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          textAlign={"center"}
        >
          <Switch
            checked={params.rowData?.record.reviewed}
            value={params.rowData?.record.reviewed}
            key={`vcell-${params.rowIndex}-${params.columnIndex}`}
            onChange={(event, checked) => {
              onRecordChanged(
                { ...params.rowData?.record, reviewed: checked },
                params.rowData?.employee!
              );
            }}
          />
        </Grid>
      );
    } else if (params.column.key == "paid") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
          textAlign={"center"}
        >
          <Checkbox
            disabled={true}
            checked={params.rowData?.record.paid}
            value={params.rowData?.record.paid}
            onChange={(event, checked) => {
              onRecordChanged(
                { ...params.rowData?.record, paid: checked },
                params.rowData?.employee!
              );
            }}
            key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          />
        </Grid>
      );
    } else if (params.column.key == "vacancy_name") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <Typography>
            <ReferencePreview
              key={`preview-${params.rowIndex}-${params.columnIndex}`}
              size="tiny"
              previewProperties={["title", "rateHour"]}
              reference={params.rowData?.employee.values.vacancy!}
            />
          </Typography>
        </Grid>
      );
    } 
    else if (params.column.key == "plan") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
          textAlign={'right'}
        >
          {}
          <ReferencePreview
          size="tiny"
          allowEntityNavigation={false}
          previewProperties={["hoursShift"]}
         
         
          disabled={true}

              key={`preview-${params.rowIndex}-${params.columnIndex}`}
             
            reference ={params.rowData?.vacancy!}
              // reference={params.rowData?.employee.values.vacancy!}
            />
        </Grid>
      );
    } else if (params.column.key == "hours") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <Typography textAlign={"right"} paddingRight={"20px"}>
            {params.rowData?.record.hours}
          </Typography>
        </Grid>
      );
    } else if (params.column.key == "rateHour") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <Typography textAlign={"right"} paddingRight={"20px"}>
            {params.rowData?.record.rate}
          </Typography>
        </Grid>
      );
    } else if (params.column.key == "sum") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <Typography
            textAlign={"end"}
            paddingRight={"20px"}
            fontWeight={"bold"}
          >
            {params.rowData?.record.amount}
          </Typography>
        </Grid>
      );
    } else if (params.column.key == "modifiers") {
      const bonus = params.rowData?.record.bonus;
      const deduction = params.rowData?.record.deduction;
      const bonusComment = params.rowData?.record.bonusComment;
      const deductionComment = params.rowData?.record.deductionComment;

      const hasBonus = bonus && bonus > 0;

      const hasDeduction = deduction && deduction > 0;
      const hasBonusComment = bonusComment && bonusComment.length > 0;
      const hasDeductionComment =
        deductionComment && deductionComment.length > 0;
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <Grid container alignItems={"end"} direction={"column"} p={1} px={4}>
            {hasBonus && (
              <Grid item xs={6}>
                <Tooltip
                  title={
                    bonusComment && `Комментарий к премии:\n${deductionComment}`
                  }
                >
                  <Typography color={"green"}>+{bonus}</Typography>
                </Tooltip>
              </Grid>
            )}
            {hasDeduction && (
              <Grid item xs={6}>
                <Tooltip
                  title={
                    deductionComment &&
                    `Комментарий к вычету:\n${deductionComment}`
                  }
                >
                  <Typography color={"red"}>-{deduction}</Typography>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
      );
    } else if (params.column.key == "index") {
      return (
        <Grid
          key={`vcell-${params.rowIndex}-${params.columnIndex}`}
          width={params.width}
          alignSelf={"center"}
        >
          <Typography>{params.rowIndex + 1}</Typography>
        </Grid>
      );
    } else {
      const id = `${params.rowIndex}`;
        // params.rowData?.record.date.toString() +
        // params.rowData?.employee.values.displayName! +
        // params.rowData?.employee.values.vacancy!.pathWithId;
      return (
        <React.Fragment key={`dialog-fragment-${params.rowIndex}-${params.columnIndex}`}>
          <Grid
            key={`vcell-${params.rowIndex}-${params.columnIndex}`}
            width={params.width}
            alignSelf={"center"}
          >
            <Button     key={`dialogbutton-${params.rowIndex}-${params.columnIndex}`} onClick={() => setRecordOpen(id)}>
              Просмотреть запись
            </Button>

            <Dialog
              key={`dialog-${params.rowIndex}-${params.columnIndex}`}
              maxWidth={"lg"}
              onClose={onDialogClose}
              open={recordOpen == id}
            >
              <Grid container direction={"row"} p={1} px={4}>
                <Grid item xs={6}>
                  <EntityPreview
                    collection={employeesRecordsSubCollection}
                    entity={
                      {
                        id: params.rowData?.employee.id!,
                        values: params.rowData?.record!,
                        path: params.rowData?.employee.path,
                      } as Entity<EmployeeRecord>
                    }
                    path="/record-details"
                  />
                </Grid>
                <Grid item xs={6}>
                  <EntityByReferencePreview
                    collection={previewVacancyColleciton}
                    reference={params.rowData?.employee.values.vacancy!}
                    path="vacancies"
                  />
                  {/* <EntityPreview
                    collection={vacancyCollection}

                    entity={
                      {
                        id: params.rowData?.vacancy.id!,
                        values:  params.rowData?.vacancy,
                        path: "vacancies",
                      } as Entity<Vacancy>
                    }
                    path="/record-details"
                  /> */}
                </Grid>
              </Grid>
            </Dialog>
          </Grid>
        </React.Fragment>
      );
    }
  };

  const [sortBy, setSortBy] = useState<[string, "asc" | "desc"] | undefined>([
    "date",
    "asc",
  ]);

  const [sortedData, setSortedData] = useState<RecordWithEmployee[]>(data);

  useEffect(() => {
    const sortField = sortBy?.[0];
    const sortDirection = sortBy?.[1];

    if (!sortField || !sortDirection) {
      setSortedData(data);
      return;
    }

    if (sortField === "date") {
      const sorted = [...data].sort((a, b) => {
        if (a.record.date > b.record.date) return sortDirection === "asc" ? 1 : -1;
        if (a.record.date < b.record.date) return sortDirection === "asc" ? -1 : 1;
        return 0;
      });

      setSortedData(sorted);
    }
  }, [data, sortBy]);

  return data.length > 0 ? (
    <Grid
      width={"100%"}
      height={800}
      minHeight={800}
      flexBasis={1}
      flexDirection={"column"}
      flexGrow={1}
      color={green}
      key={"vtable   "}
    >
      <VirtualTable
        data={sortedData}
        sortBy={sortBy}
        onSortByUpdate={setSortBy}
        columns={columns}
        cellRenderer={(params) => cellRenderer(params)}
        size="m"
      />
    </Grid>
  ) : (
    <Typography py={8} textAlign={"center"}>
      Нет записей
    </Typography>
  );
};

export default RecordsTableView;
