import { buildCollection, EntityReference } from "firecms";
// import { Vacancy } from "./vacancies";


export type ClientBill = {
    vacancyRef: EntityReference
    amount: number
    isPaid: boolean
    periodStartDate: Date,
    periodEndDate: Date,
    paymentDate?: Date,
    vacancyTitle: string
}


export const currentBillsCollection = buildCollection<ClientBill>({
    name: "Счета",
    singularName: "Счёт",
    icon: "CurrencyRuble",
    path: "bills",
    permissions: (
        // { authController }
        ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],

    properties: {
        vacancyRef: {
            name: "Вакансия",
            dataType: "reference",
            path: "vacancies",
            previewProperties: ["title"]
        },
        vacancyTitle: {
            name: "Название вакансии/счета",
            description: "Текст на картчоке в списке счетов",
            dataType: "string",
            validation: { required: true },
        },
        isPaid: {
            name: "Оплачен",
            dataType: "boolean",
            validation: { required: true }
        },
        paymentDate: {
            name: "Дата платежа",
            dataType: "string",
            validation: { required: false }
        },
        periodStartDate: {
            name: "Дата начала периода",
            dataType: "date",
            validation: { required: true }
        },
        periodEndDate: {
            name: "Дата конца периода",
            dataType: "date",
            validation: { required: true }
        },
        amount: {
            name: "Сумма",
            dataType: "number",
            validation: { required: true }
        },
    }
})
