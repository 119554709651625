import { Button, Grid, TextField, Typography } from "@mui/material";
import {
  Entity,
  EntityReference,
  pick,
  useDataSource,
  useReferenceDialog,
} from "firecms";
import { Client } from "../../collections/clients";
import { useCallback, useEffect, useState } from "react";
import { filterByClientColleciton } from "../components/filter-collections";
import {
  LocalizationProvider,
  StaticDatePicker,
  ruRU,
  DatePicker,
} from "@mui/x-date-pickers";
import "dayjs/locale/de";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru"; // Import the Russian locale
import { async } from "rxjs";
import { Vacancy, vacancyCollection } from "../../collections/vacancies";
import { Employee, employeesCollection } from "../../collections/employees";
import VirtualSuperTable from "./VirtualSuperTable";
import { Applicant, applicantsCollection } from "../../collections/applicant";
import { creditCardsCollection } from "../../collections/cards";
import Papa from "papaparse";

import { saveAs } from "file-saver";
import { Download } from "@mui/icons-material";
import DownloadCSVButton from "./DownloadCsvButton";

function convertToCSV(data: any[]) {
  const csv = Papa.unparse(data);
  return csv;
}

interface SuperTabelPageProps {}

export const SuperTabelPage: React.FC<SuperTabelPageProps> = ({}) => {
  const ds = useDataSource();

  const [selectedClients, setSelectedClients] = useState<
    Entity<Partial<Client>>[]
  >([]);


  const firstMomentOfDay = (date: Date) => {
    return date && new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0,0) || null;
   
  }

  const lastMomentOfDay = (date: Date) => {
    return date && new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59,59,999) || null;
  }

  const [dateFrom, setDateFrom] = useState<Date | null>(firstMomentOfDay(new Date()));
  const [dateTo, setDateTo] = useState<Date | null>(lastMomentOfDay(new Date()));


  const pickClient = useReferenceDialog({
    collection: filterByClientColleciton,
    description: "Фильтр по работаделям",
    path: "user_profiles/clients/profiles",
    multiselect: true,
    onMultipleEntitiesSelected: setSelectedClients,
  });

  const loadTabels = useCallback(async () => {
    let rows: any[] = [];

    if (selectedClients.length == 0) {
      return;
    }

    // for (const client of selectedClients) {
    const vacancies = await ds.fetchCollection<Vacancy>({
      collection: vacancyCollection,
      path: "vacancies",
      filter: {
        employer: [
          "in",
          selectedClients.map((c) => new EntityReference(c.id, c.path)),
        ],
      },
    });

    const employees = await ds.fetchCollection<Employee>({
      collection: employeesCollection(true),
      path: "employees",
      filter: {
        vacancy: [
          "in",
          vacancies.map((c) => new EntityReference(c.id, c.path)),
        ],
      },
    });

    console.log(employees);

    const workerDictionary = employees.reduce<Record<string, EntityReference>>(
      (acc: any, employee) => {
        acc[employee.id] = employee.values.workerProfile;

        // acc[employee.id] = employee.values.workerProfile.id;
        return acc;
      },
      {}
    );

    const cardsDictionary = employees.reduce((acc: any, employee) => {
      acc[employee.id] = undefined;
      return acc;
    }, {});

    const applicantsDictionary: Record<string, Entity<Applicant>> = {};
    for (const [employeeId, workerProfile] of Object.entries(
      workerDictionary
    )) {
      const worker = await ds.fetchEntity({
        collection: applicantsCollection,
        path: "user_profiles/applicants/profiles",
        entityId: workerProfile.id,
      });

      const paymentMethods = await ds.fetchCollection({
        collection: creditCardsCollection,
        path: workerProfile.pathWithId + "/payment_methods",
        filter: {
          active: ["==", true],
        },
      });

      const card =
        paymentMethods.length > 0 && paymentMethods[0].values.cardNumber;

      if (worker) applicantsDictionary[employeeId] = worker;
      if (card) cardsDictionary[employeeId] = card;
    }

    // const download = useCallback(async () => {
    //   const csv = convertToCSV(tabels);
    // }, [tabels]);

    console.log("worker", workerDictionary);
    const records = employees
      .map((employee) => {
        const vacancy = vacancies.find(
          (v) => v.id == employee.values.vacancy.id
        )?.values;

        const client = selectedClients.find(
          (c) => c.id == employee.values.employer.id
        );
        const applicant = applicantsDictionary[employee.id];

        const card = cardsDictionary[employee.id];

        return employee.values.records.filter(r=>r.reviewed && !r.paid).map<TabelDayRecord>((record) => {
          // comments
          const comment = record.comment || "";
          const premComment =
            (record.bonusComment &&
              `${record.comment && ";"} Премия: ` + record.bonusComment) ||
            "";
          const deductComment =
            (record.deductionComment &&
              `${
                ((record.comment || record.deductionComment) && ";") || ""
              } Удержа: ` + record.deductionComment) ||
            "";

          return {
            recordId:
              employee.id +
              "_" +
              record.date.toLocaleDateString().replaceAll("/", "_") +
              "_" +
              employee.values.vacancy.id,
            GUID_FL: employee.values.workerProfile.id,

            paid: record.paid || false,
            reviewed: record.reviewed || false,

            date: record.date.toLocaleDateString(),
            FIO_FL: employee.values.displayName || "Без имени",
            Telefon_FL: applicant?.values?.phoneNumber || "+7?",
            CardNO: card,
            VacancyTitle: vacancy?.title || "Без названия",

            VacancySpecialtes:
              vacancy?.specialties.map((s) => s.name).join(", ") || "?",
            VacancyAddress: vacancy?.locationDetails.vacancyAddress || "?",
            GUID_UL: client?.id || "?",
            CLIENT_TITLE: client?.values.titleFull || "?",
            CLIENT_INN: +(client?.values.inn || 0),
            CLIENT_KPP:
              (client?.values as any)?.kpp && +(client?.values as any).kpp,
            CLIENT_SERVICE: "",
            CLIENT_CostH: vacancy!.rateHour,
            DAY: 1,
            H: record!.hours || 0,
            SUM: record!.amount || 0,

            DEDUCT: record!.deduction,
            BONUS: record!.bonus,
            TOTAL: record!.amount || 0,
            COMMENT: comment + premComment + deductComment,
          };
        });
      })
      .flat(2);

    setTabels(records);
  }, [selectedClients, dateFrom, dateTo]);

  const [tabels, setTabels] = useState<any[]>([]);

  const headerConfig = {
    recordId: 150,
    GUID_FL: 150,
    paid: 150,
    reviewed: 50,
    date: 150,
    FIO_FL: 150,
    Telefon_FL: 150,
    CardNO: 150,
    VacancyTitle: 150,
    VacancySpecialtes: 100,
    VacancyAddress: 200,
    GUID_UL: 100,
    CLIENT_TITLE: 100,
    CLIENT_INN: 100,
    CLIENT_KPP: 100,
    CLIENT_SERVICE: 50,
    CLIENT_CostH: 50,
    DAY: 50,
    H: 50,
    SUM: 50,
    DEDUCT: 50,
    BONUS: 50,
    TOTAL: 50,
    COMMENT: 150,
  };
  const header = Object.entries(headerConfig).map(([label, width]) => ({
    label,
    width,
  }));

//   const exportCsv = useCallback(async () => {
//     await saveAs(convertToCSV(tabels), "export.csv");
//   }, [tabels]);

  return (
    <Grid p={4}>
      <LocalizationProvider
        adapterLocale={ruLocale}
        dateAdapter={AdapterDateFns}
      >
        {/* Calendars */}
        <Grid container columnGap={4}>
          <DatePicker
            label="От"
            value={dateFrom}
            maxDate={dateTo || undefined}
            onChange={(newValue) => {
            setDateFrom(newValue && new Date(newValue.getFullYear(),newValue.getMonth(),newValue.getDate(),0,0,0,0) || null);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="До"
            value={dateTo}
            minDate={dateFrom || undefined}
            onChange={(newValue) => {
             setDateTo(newValue && new Date(newValue.getFullYear(),newValue.getMonth(),newValue.getDate(),23,59,59,999 ));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>
      </LocalizationProvider>
      <Button onClick={() => pickClient.open()}>Выбрать работодателей</Button>
      {selectedClients.length > 0 && (
        <Button onClick={loadTabels}>Cформировать отчет</Button>
      )}
      <Grid container direction={"row"} columnGap={1}>
        {selectedClients.map((client) => (
          <Typography variant="label">
            {(client.values as any)?.["titleShort"] ||
              client.values.titleFull ||
              "Без наименования"}
          </Typography>
        ))}
      </Grid>

      <DownloadCSVButton data={tabels} />

      <Grid item xs={12} container direction={"column"} rowGap={1}>
        {tabels && <VirtualSuperTable data={tabels} headers={header} />}
      </Grid>
    </Grid>
  );
};

interface TabelDayRecord {
  recordId: string;
  GUID_FL: string;
  paid: boolean;
  reviewed: boolean;
  date: string;
  FIO_FL: string;
  Telefon_FL: string;
  CardNO: string;
  VacancyTitle: string;
  VacancySpecialtes: string;
  VacancyAddress: string;
  GUID_UL: string;
  CLIENT_TITLE: string;
  CLIENT_INN: number;
  CLIENT_KPP: number;
  CLIENT_SERVICE: string;
  CLIENT_CostH: number;
  DAY: number;
  H: number;
  SUM: number;
  DEDUCT?: number;
  BONUS?: number;
  TOTAL: number;
  COMMENT?: string;
}
