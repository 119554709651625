import {
  Button,
  Chip,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  StaticDatePicker,
  ruRU,
  DatePicker,
} from "@mui/x-date-pickers";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { ruRU  } from "@mui/material/locale";

import React, { FC, Fragment, useEffect, useState } from "react";

import "dayjs/locale/de";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ruLocale from "date-fns/locale/ru"; // Import the Russian locale
import {
  Entity,
  EntityReference,
  ReferencePreview,
  VirtualTable,
  useDataSource,
  useReferenceDialog,
} from "firecms";
import { Vacancy } from "../../collections/vacancies";
import {
  Employee,
  EmployeeRecord,
  employeesCollection,
} from "../../collections/employees";
import { Client } from "../../collections/clients";
import {
  filterByClientColleciton,
  filterByEmployees,
  filterByVacancyColleciton,
} from "./filter-collections";
import { VirtualTableRow } from "../../../../../lib/src/core/components/Table/VirtualTableRow";
import { VirtualTableCell } from "../../../../../lib/src/core/components/Table/VirtualTableCell";
import RecordsTableView from "./RecordsTable";
import FilterWithChips from "./FIlterWithChips";
import { green } from "@mui/material/colors";
import { CustomReferencePreview } from "./CustomReferencePreview";
import GroupedItems from "./GroupedChips";

interface Props {}

const TabelsModerationPage: FC<Props> = ({}) => {

  const firstMomentOfDay = (date: Date) => {
    return date && new Date(date.getFullYear(),date.getMonth(),date.getDate(),0,0,0,0) || null;
   
  }

  const lastMomentOfDay = (date: Date) => {
    return date && new Date(date.getFullYear(),date.getMonth(),date.getDate(),23,59,59,999) || null;
  }

  const [dateFrom, setDateFrom] = React.useState<Date | null>(firstMomentOfDay(new Date()));
  const [dateTo, setDateTo] = React.useState<Date | null>(lastMomentOfDay(new Date()));

  const [selectedClients, setSelectedClients] = useState<
    Entity<Partial<Client>>[]
  >([]);
  const [selectedEmployees, setSelectedEmployees] = useState<
    Entity<Employee>[]
  >([]);
  const [selectedVacancies, setSelectedVacancies] = useState<Entity<Vacancy>[]>(
    []
  );

  const datasource = useDataSource();

  const [vacancyTooltipId, setVacancyTooltipId] = useState<
    string | undefined
  >();

  const handleVacancyChipDelete = (id: string) => {
    setSelectedVacancies(selectedVacancies.filter((v) => v.id != id));
  };

  const handlClientChipDelete = (id: string) => {
    setSelectedClients(selectedClients.filter((v) => v.id != id));
  };

  const handleEmployeeChipDelete = (id: string) => {
    setSelectedEmployees(selectedEmployees.filter((v) => v.id != id));
  };

  useEffect(() => {
    const clientFilterEnabled = selectedClients.length > 0;
    const vacanciesFilterEnabled = selectedVacancies.length > 0;
    const employeesFilterEnabled = selectedEmployees.length > 0;

    // vacancies will left unchanged if no clients selected
    let filteredVacancies = selectedVacancies;

    // actualize vacancies list any way
    if (clientFilterEnabled && vacanciesFilterEnabled) {
      filteredVacancies = filteredVacancies.filter((vacancy) =>
        selectedClients.some(
          (client) => client.id == vacancy.values.employer.id
        )
      );
    }

    setSelectedVacancies(filteredVacancies);

    if (selectedEmployees.length > 0) {
      setSelectedEmployees(
        selectedEmployees.filter((employee) => {
          // if any vacancy is selected - emplyees should be filtered
          return filteredVacancies.some(
            (vacancy) => vacancy.id == employee.values.vacancy.id
          );
        })
      );
      // fetchAll();
    }
  }, [
    selectedClients,
    // selectedVacancies,
    // selectedEmployees,
  ]);
  
  useEffect(() => {
    const clientFilterEnabled = selectedClients.length > 0;
    const vacanciesFilterEnabled = selectedVacancies.length > 0;
    const employeesFilterEnabled = selectedEmployees.length > 0;

    // vacancies will left unchanged if no clients selected
    let filteredVacancies = selectedVacancies;

    // actualize vacancies list any way
    if (clientFilterEnabled && vacanciesFilterEnabled) {
      filteredVacancies = filteredVacancies.filter((vacancy) =>
        selectedClients.some(
          (client) => client.id == vacancy.values.employer.id
        )
      );
    }


    if (selectedEmployees.length > 0) {
      setSelectedEmployees(
        selectedEmployees.filter((employee) => {
          // if any vacancy is selected - emplyees should be filtered
          return filteredVacancies.some(
            (vacancy) => vacancy.id == employee.values.vacancy.id
          );
        })
      );
      // fetchAll();
    }
  }, [
    // selectedClients,
    selectedVacancies,
    // selectedEmployees,
  ]);

  const getEmployeeFilters = () => {
    const filterEntries = [];

    if (selectedClients.length > 0) {
      filterEntries.push(
        ...Object.entries({
          employer: [
            "in",
            selectedClients.map((c) => new EntityReference(c.id, c.path)),
          ],
        })
      );
    }

    if (selectedVacancies.length > 0) {
      filterEntries.push(
        ...Object.entries({
          vacancy: [
            "in",
            selectedVacancies.map((c) => new EntityReference(c.id, c.path)),
          ],
        })
      );
    }

    if (filterEntries.length > 0) {
      return Object.fromEntries(filterEntries);
    } else {
      return undefined;
    }
  };

  const pickEmployees = useReferenceDialog<any>({
    collection: filterByEmployees,
    path: "employees",

    forceFilter: getEmployeeFilters() as any | undefined,

    multiselect: true,
    onMultipleEntitiesSelected(entities) {
      setSelectedEmployees(entities);
    },
  });

  const pickClient = useReferenceDialog({
    collection: filterByClientColleciton,
    description: "Фильтр по работаделям",
    path: "user_profiles/clients/profiles",
    multiselect: true,
    onMultipleEntitiesSelected: setSelectedClients,
  });

  const pickVacancy = useReferenceDialog({
    collection: filterByVacancyColleciton,
    path: "vacancies",
    multiselect: true,

    forceFilter:
      selectedClients.length > 0
        ? {
            employer: [
              "in",
              selectedClients.map((c) => new EntityReference(c.id, c.path)),
            ],
          }
        : undefined,

    onMultipleEntitiesSelected(entities) {
      setSelectedVacancies(entities);
    },
  });

  const fetchAll = async () => {
    datasource
      .fetchCollection({
        collection: employeesCollection(true),
        path: "employees",
        filter: getEmployeeFilters() as any | undefined,
      })
      .then((data) => {
        console.log(data);
        setSelectedEmployees(data);
      });
  };

  //   vacancyPicker.open({ collection: vacancyCollection,path:'vacancy',  });
  return (
    <Grid px={4} paddingBottom={4}>
      <Typography py={4} variant="h5">
        Модерация табелей
      </Typography>
      <LocalizationProvider
        adapterLocale={ruLocale}
        dateAdapter={AdapterDateFns}
      >
        {/* Calendars */}
        <Grid container columnGap={4}>
          <DatePicker
            label="От"
            value={dateFrom}
            maxDate={dateTo || undefined}
            onChange={(newValue) => {
              setDateFrom(newValue && new Date(newValue.getFullYear(),newValue.getMonth(),newValue.getDate(),0,0,0,0) || null);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="До"
            value={dateTo}
            minDate={dateFrom || undefined}
            onChange={(newValue) => {
              setDateTo(newValue && new Date(newValue.getFullYear(),newValue.getMonth(),newValue.getDate(),23,59,59,999 ));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Grid>

        {/* Filter column */}
        <Grid
          paddingTop={1}
          rowGap={1}
          container
          direction={"column"}
          alignItems={"start"}
          justifyContent={"start"}
          alignContent={"start"}
        >
          {/* Client filter */}
          <Grid container direction={"row"} alignItems={"center"}>
            {/* Client filter button */}
            <Grid item width={250}>
              <Button onClick={pickClient.open}>Выбрать работодателя</Button>
            </Grid>
            {/* Client filter selection */}
            <Grid item>
              <FilterWithChips
                labelRenderer={(item) => (
                  <Typography>
                    {item.values["titleShort"] || item.values.titleFull}
                  </Typography>
                )}
                nodesRenderer={(items) => items.map((i) => i.node)}
                noneText="Все работодатели"
                onDelete={handlClientChipDelete}
                selectedItems={selectedClients}
              />
            </Grid>
          </Grid>

          <Grid container direction={"row"} alignItems={"center"}>
            <Grid item width={250}>
              <Button onClick={pickVacancy.open}>Выбрать вакансии</Button>{" "}
            </Grid>

            <Grid item>
              <FilterWithChips
                labelRenderer={(item: Entity<Partial<Vacancy>>) => (
                  <Typography>{item.values.title}</Typography>
                )}
                nodesRenderer={(items) => items.map((i) => i.node)}
                noneText="Все вакансии"
                onDelete={handleVacancyChipDelete}
                selectedItems={selectedVacancies}
              />
            </Grid>
          </Grid>

          <Grid container direction={"row"} alignItems={"top"}>
            <Grid item width={250}>
              <Button onClick={pickEmployees.open}>Выбрать сотрудника</Button>{" "}
            </Grid>

            <Grid item>
              <FilterWithChips
                labelRenderer={(item: Entity<Employee>) => (
                  <CustomReferencePreview
                    previewProperties={["firstName", "familyName"]}
                    size="small"
                    allowEntityNavigation={false}
                    reference={
                      new EntityReference(
                        item.values.workerProfile.id,
                        item.values.workerProfile.path
                      )
                    }
                    renderProperties={(entity, resolvedProperties) => {
                      return (
                        <Typography>{`${entity?.values.firstName} ${entity?.values.familyName}`}</Typography>
                      );
                    }}
                  />
                )}
                nodesRenderer={(items) => (
                  <GroupedItems
                    items={items}
                    groupingIdSelector={(item:any) => item.id}
                    groupingSelector={(item) =>
                      (item.item as Entity<Employee>).values.vacancy
                    }
                    itemRenderer={({ item, node }, index) => <Fragment key={`fragment-${item.id}-${index}`}>{node}</Fragment>}
                    groupTitleRenderer={( groupTitle,group ) => (
                    //  <Typography> {groupTitle.id}</Typography>
                      <CustomReferencePreview
                        size="tiny"
                        previewProperties={["title"]}
                        renderProperties={(entity: any)=>`${entity?.values.title} (${entity?.values.employerInfo.displayName})`}
                        reference={group.groupingObject as EntityReference}
                      
                      />
                    )}
                  />
                )}
                noneText="Все сотрудники"
                onDelete={handleEmployeeChipDelete}
                selectedItems={selectedEmployees}
              />
            </Grid>
            {selectedEmployees.length <= 0 && (
              <Button
                onClick={() => {
                  fetchAll();
                }}
              >
                Показать всех
              </Button>
            )}
          </Grid>
        </Grid>

        <Grid>
          <RecordsTableView
          selectedVacancies={selectedVacancies}
            data={selectedEmployees.flatMap((employee: Entity<Employee>) =>
              employee.values.records
                .filter((record) => {
                  if (dateFrom && dateTo) {
                    return record.date >= dateFrom && record.date <= dateTo;
                  }
                  if (dateFrom) {
                    return record.date >= dateFrom;
                  }
                  if (dateTo) {
                    return record.date <= dateTo;
                  }
                  return true;
                })
                .map((record) => ({
                  employee: employee,
                  record: record,
                  vacancy: employee.values.vacancy,
                }))
            )}
            onRecordChanged={async function (
              record: Record<string, any>,
              employee: Entity<Employee>
            ): Promise<void> {
              console.log(record);
              console.log(employee);

              const updatedRecords = employee.values.records.map(
                (r: Record<string, any>) => {
                  if (r.date == record.date) {
                    return record;
                  }
                  return r;
                }
              ) as EmployeeRecord[];

              // setSelectedEmployees([])
              setSelectedEmployees([
                ...selectedEmployees.map((e) => {
                  if (e.id == employee.id) {
                    return {
                      ...employee,
                      values: {
                        ...employee.values,
                        records: updatedRecords,
                      },
                    };
                  } else {
                    return e;
                  }
                }),
              ]);
              await datasource.saveEntity({
                collection: employeesCollection(false),
                entityId: employee.id,
                path: employee.path,
                status: "existing",
                values: {
                  records: updatedRecords,
                },
              });
            }}
          />
        </Grid>
      </LocalizationProvider>
    </Grid>
  );
};

export default TabelsModerationPage;
