import {
  buildCollection,
  buildEntityCallbacks,
  buildProperty,
  EntityReference,
} from "firecms";
import { LocationDetails } from "../../domain/entities/location-details";
import {
  Employment,
  KnowledgeRussian,
  VacancyStatus,
} from "../../domain/enums/enums";
import { buildEnumKnowledgeRussian } from "../builders/enum_builders";
import { applicationsCollection } from "./applications";
import { clientsCollection } from "./clients";
import { employeesCollection } from "./employees";
import { Specialty } from "./specialties";
import { vacancyStatusEnumValues } from "../../domain/enums/enumValues";
import { Timestamp } from "firebase/firestore";

export type Vacancy = {
  // id: string
  createdAt: Date;
  specialties: [Specialty];
  status: string;
  title: string;
  description: string;
  locationDetails: LocationDetails;
  startWork: any;
  completionWorks: any;
  employment: Employment;
  rateHour: number;
  hoursShift: number;
  numberShifts: number;
  scheduleWorks: string;
  numberEmployees: number;
  covid: boolean;
  honeyBook: boolean;
  knowledgeRussian: KnowledgeRussian;
  employer: EntityReference;
  unresolvedApplications: number;
};

// export type EmployerInfo = {
//     displayName: string
//     imageUrl?: string
//     employerFeedback: EmployerFeedback,
// }

export declare class LocationDetailsClass {
  constructor(latitude: number, vacancyAddress: string, longitude: number);

  /**
   * The latitude of this GeoPoint instance.
   */
  readonly latitude: number;
  readonly vacancyAddress: number;

  /**
   * The longitude of this GeoPoint instance.
   */
  readonly longitude: number;
}

const locationDetails = buildProperty<LocationDetails>({
  name: "Детали локации",
  dataType: "map",
  properties: {
    vacancyAddress: {
      name: "Адрес",
      validation: { required: true },
      dataType: "string",
    },
    nearestMetro: {
      name: "Ближайшие станции метро",
      validation: { required: false },
      dataType: "array",
      of: {
        name: "Нвазние станции",
        dataType: "string",
      },
    },
    longitude: {
      name: "lon",
      validation: { required: true, min: -180, max: 180 },
      dataType: "string",
    },
    latitude: {
      name: "lat",
      validation: { required: true, min: -180, max: 180 },
      dataType: "string",
    },
  },
});

// const employerInfo =  buildProperty<EmployerInfo>({
//     name: "Детали локации",
//     dataType: "map",
//     properties: {
//         vacancyAddress: {
//             name: "Адрес",
//             validation: { required: true },
//             dataType: "string"
//         },
//         nearestMetro: {
//             name: "Ближайшие станции метро",
//             description: "Action type that determines the user flow",
//             validation: { required: false },
//             dataType: "array",
//             of: {
//                 dataType: "string"
//             }
//         },
//         longitude: {
//             name: "lon",
//             validation: { required: true, min: -180, max: 180 },
//             dataType: "string",
//         },
//         latitude: {
//             name: "lat",
//             validation: { required: true, min: -180, max: 180 },
//             dataType: "string"
//         }
//     }
// }
// );

export const vacancyCallbacks = buildEntityCallbacks({
  onPreSave: async ({
    // collection,
    // path,
    // entityId,
    values,
    status,
    context,
  }) => {
    // for (const entry of properties) {
    //     if (isEntryNullable(entry)) {
    //         if (entry[1] == '') {
    //             (values as any)[entry[0]] = undefined
    //         }
    //     } else {
    //         console.log(`Entry ${entry[0]} is not nullable`);
    //     }
    // }

    const employer = await context.dataSource.fetchEntity({
      path: values.employer.path,
      entityId: values.employer.id,
      collection: clientsCollection,
    });
    const erased = employer?.values as any;
    const displayName = erased["titleShort"] || erased["titleFull"];
    values.clientId = values.employer.id;
    // return the updated values
    if (status == "new") {
      console.log("status is new");

      // values.clientId = values.employer.id

      values.employerInfo = {
        id: employer?.id,
        displayName: displayName,
        imageUrl: employer?.values.imageUrl,
        feedbacks: [],
      };
      return values;
    } else {
      if (status == "existing") {
        console.log(employer?.values);

        values.employerInfo = {
          id: employer?.id,
          displayName: displayName,
          imageUrl: employer?.values.imageUrl,
          feedbacks: [],
        };
      }
      return values;
    }
  },
});

export const vacancyCollection = buildCollection<Vacancy>({
  name: "Вакансии",
  singularName: "Вакансия",
  icon: "ContentPasteSearch",
  path: "vacancies",
  group: "Активность",
  callbacks: vacancyCallbacks,
  hideIdFromForm: true,
  permissions: () =>
    // { authController }
    ({
      read: true,
      edit: true,
      create: true,
      delete: true,
    }),
  subcollections: [employeesCollection(false), applicationsCollection(false)],
  // subcollections: [
  //     localeCollection
  // ],

  properties: {
    // id: {
    //     name: "",
    //     dataType: "string",
    //     validation: { required: true },
    //     readOnly: true,
    // },
    employer: {
      name: "Работодатель",
      dataType: "reference",
      path: "user_profiles/clients/profiles",
    },
    title: {
      name: "Заголовок",
      dataType: "string",
      validation: { required: true },
    },
    specialties: {
      name: "Специальности",
      dataType: "array",
      of: {
        dataType: "map",
        properties: {
          category: {
            name: "Категория",
            dataType: "string",
          },
          name: {
            name: "Специальность",
            dataType: "string",
          },
        },
        previewProperties: ["name"],
        // previewAsTag: true
      },
      validation: { required: true },
    },

    status: ({ values }: any) => ({
      name: "Статус вакансии",
      dataType: "string",
      validation: { required: true },
      enumValues: vacancyStatusEnumValues.map((v) => {
        return v.id == VacancyStatus.newResponses &&
          (values.unresolvedApplications || 0) > 0
          ? {
              ...v,
              name: `Есть новые отклики (${values.unresolvedApplications})`,
            }
          : v;
      }),
    }),

    description: {
      name: "Описание",
      dataType: "string",
      validation: { required: true },
    },
    // Location details

    locationDetails: locationDetails,
    startWork: {
      name: "Дата начала работ",
      dataType: "date",
      validation: { required: true },
    },

    completionWorks: {
      name: "Дата окончания работ",
      dataType: "date",
      validation: { required: true },
    },

    employment: {
      name: "Занятость",
      dataType: "string",
      validation: { required: true },
      enumValues: {
        permanent: "Постоянная",
        temporary: "Временная",
      },
    },

    rateHour: {
      name: "Ставка в час",
      dataType: "number",
      validation: { required: true },
    },

    hoursShift: {
      name: "Часов в смене",
      dataType: "number",
      validation: { required: true },
    },

    numberShifts: {
      name: "Кол-во смен",
      dataType: "number",
      validation: { required: true },
    },

    scheduleWorks: {
      name: "График работ",
      dataType: "string",
      validation: { required: true },
    },

    numberEmployees: {
      name: "Требуется сотрудников",
      dataType: "number",
      validation: { required: true },
    },

    covid: {
      name: "CoVID",
      dataType: "boolean",
      validation: { required: true },
    },

    honeyBook: {
      name: "мед.книжка",
      dataType: "boolean",
      validation: { required: true },
    },
    unresolvedApplications: {
      name: "Новые отклики",
      dataType: "number",
      validation: { integer: true },
      readOnly: true,
      defaultValue: 0,
    },
    knowledgeRussian: {
      name: "Знание русского",
      dataType: "string",
      validation: { required: true },
      enumValues: buildEnumKnowledgeRussian(),
    },

    createdAt: {
      name: "Дата создания",
      dataType: "date",
      validation: { required: false },
      mode: "date",
    },
  },
});
