import { VacancyStatus } from "./enums";

export const vacancyStatusEnumValues = [ {
    id: VacancyStatus.awaitingResponse,
    // disabled: propertyValue == VacancyStatus.moderation,
    label: "Ожидание откликов",

},
{
    id: VacancyStatus.newResponses,
    // disabled: propertyValue == VacancyStatus.moderation,
    label: "Есть новые отклики",

},
{
    id: VacancyStatus.waitingStartWork,
    // disabled: propertyValue == VacancyStatus.moderation,
    label: "Ожидание начала работ",
},
{
    id: VacancyStatus.atWork,
    // disabled: propertyValue == VacancyStatus.moderation,
    label: "В работе",
},
{
    id: VacancyStatus.moderation,
    // disabled: propertyValue == VacancyStatus.moderation,
    label: "Вакансия на модерации",
},
{
    id: VacancyStatus.failedModeration,
    label: "Не прошла модерацию",
},
// {
//     id: VacancyStatus.tabelOnModeration,
//     // disabled: propertyValue == VacancyStatus.moderation,
//     label: "Табель на модерации",
// },


// {
//     id: VacancyStatus.tabelFailedModeration,
//     // disabled: propertyValue == VacancyStatus.moderation,
//     label: "Табель не прошел модерацию",
// },

{
    id: VacancyStatus.paymentPending,
    label: "Ожидание оплаты",
},
{
    id: VacancyStatus.archived,
    label: "В Архиве",
},
// {
//     id: VacancyStatus.reminder,
//     // disabled: propertyValue == VacancyStatus.moderation,
//     label: "Напоминание",
// },
// {
//     id: VacancyStatus.paid,
//     // disabled: propertyValue == VacancyStatus.moderation,
//     label: "Оплачена",
// },
{
    id: VacancyStatus.inArchiveWaitingPayment,
    // disabled: propertyValue == VacancyStatus.moderation,
    label: "В архиве ожидает оплаты"
}
]


export const employeeStatusEnumValues = 

    {
        accepted: "В команде",
        active: "Вышел на работу",
        withdrawn: "Не явился",
      }