import { buildEnumValues, EnumValues } from 'firecms';

export const citizenshipBuilder = (): EnumValues => buildEnumValues(
    {
        'ru': 'РФ',
        'by': 'Беларусь',
        'ua': 'Украина',
        'uz': 'Узбекистан',
        'tj': 'Таджикистан',
        'kz': 'Казахстан',
        'am': 'Армения',
        'az': 'Азербайджан',
        'ky': 'Кыргызстан',
    }
);

export const buildEnumKnowledgeRussian = (): EnumValues => buildEnumValues(
    {
        'understand': 'Понимает',
        'understandTalk': 'Разговорный',
        'understandTalkWrite': 'Письменный',
    }
);