import {
  AdditionalFieldDelegate,
  buildCollection,
  buildEntityCallbacks,
  buildProperties,
  buildProperty,
  Entity,
  EntityPreview,
  EntityReference,
  PropertiesOrBuilders,
  PropertyOrBuilder,
  ReferencePreview,
  useCollectionFetch,
  useEntityFetch,
  useNavigationContext,
  User,
} from "firecms";
import {
  CustomEmployeeRecordsPreview,
  EmployeeRecordPreview,
} from "../components/previews/employee-record-preview";
import { Typography } from "@mui/material";
import { useBuildNavigationContext } from "../../../../lib/src/core/internal/useBuildNavigationContext";
import { Vacancy, vacancyCollection } from "./vacancies";
import { getParentReferencesFromPath } from "../../../../lib/src/core/util/parent_references_from_path";
import { employeeStatusEnumValues } from "../../domain/enums/enumValues";
// import { Vacancy } from "./vacancies";

export type Employee = {
  workerProfile: EntityReference;
  records: EmployeeRecord[];
  status?: string;
  displayName?: "";
  startDate?: Date;
  endDate?: Date;
  vacancy: EntityReference;
  employer: EntityReference;
};

export type EmployeeRecord = {
  date: Date;
  hours: number;
  comment?: string;
  deduction?: number;
  deductionComment?: string;
  bonus?: number;
  bonusComment?: string;
  rate?: number;
  amount?: number;
  paid?: boolean;
  reviewed?: boolean;
};

// records: {
//     name: "Записи",
//     dataType: "array",
//     of : {
//             name: "Запись",
//             dataType: "reference",
//             path: "records",
//             previewProperties: ["displayName", "id", "status"],
//     },
//     validation: { required: true }
// },

export const employeeRecordsCallbacks = buildEntityCallbacks<Employee>({
  //   onPreSave({ previousValues, values,status, }) {
  //     if((previousValues?.records?.length||0) > 0 && (values.records?.length||0)>0){
  //         values.records = values.records?.map((record) => ({
  //         ...record,
  //         amount: record.hours * (record.rate || 0),
  //         }));
  //         console.log('values.records',values.records)
  //     }
  //     return values;
  //   },
  //   onIdUpdate({ values, entityId }) {
  //     // values.records = values.records.map((record) => ({
  //     //   ...record,
  //     //   amount: record.hours * (record.rate || 0),
  //     // }));
  //     return entityId!;
  //   },
  // async onSaveSuccess({ status, values, entityId }) {
  //     if (status == "new") {
  //         console.log("Linking user with profile")
  //         const requestOptions = {
  //             method: 'PATCH',
  //             headers: { 'Content-Type': 'application/json' },
  //             body: JSON.stringify({
  //                 userId: values.userId,
  //                 profileId: entityId,
  //                 userRole: "client",
  //                 legalForm: 'organization',
  //             })
  //         };
  //         let data = await fetch(`${baseApiUrl}/user/linkProfile`,
  //             requestOptions,
  //         )
  //         console.log("linked user")
  //         console.log(data)
  //     }
  // },
  // onIdUpdate(idUpdateProps) {
  //     console.log("onIdUpdate");
  //     console.log(idUpdateProps.entityId)
  //     return idUpdateProps.entityId || '';
  // },
});

export const employeesRecordsCollection = buildCollection<EmployeeRecord>({
  name: "Записи по работнику",
  singularName: "Запись табеля",
  icon: "AddReaction",
  path: "employees",
  group: "Активность",
  properties: {
    date: {
      name: "Дата",
      dataType: "date",
      mode: "date",
      validation: { required: true, unique: true },
    },
    hours: {
      dataType: "number",
      name: "Количество часов",
      validation: { required: true },
    },
    comment: {
      name: "Комментарий",
      dataType: "string",
      validation: { required: false },
    },
    deduction: {
      name: "Сумма вычетов",
      dataType: "number",
      validation: {
        required: false,
      },
    },
    deductionComment: {
      name: "Комментарий к вычету",
      dataType: "string",
      validation: {
        required: false,
      },
    },
    bonus: {
      name: "Премия",
      dataType: "number",
      validation: {
        required: false,
      },
    },
    bonusComment: {
      name: "Комментарий к премии",
      dataType: "string",
      validation: {
        required: false,
      },
    },
  },
});

export const employeesRecordsSubCollection = buildCollection<EmployeeRecord>({
  name: "Записи по работнику",
  singularName: "Табель",
  icon: "AddReaction",
  path: "employees",
  group: "Активность",
  additionalFields: [],
  properties: {
    date: {
      name: "Дата",
      dataType: "date",
      mode: "date",
      validation: { required: true, unique: true },
    },
    reviewed: {
      name: "Проверен",
      dataType: "boolean",
    },
    paid: {
      name: "Оплачен",
      dataType: "boolean",
      validation: { required: true, unique: true },
    },
    rate: {
      dataType: "number",
      name: "Ставка в час",
    },
    amount: {
      dataType: "number",
      name: "Всего",
    },
    hours: {
      dataType: "number",
      name: "Количество часов",
      validation: { required: true },
    },
    comment: {
      name: "Комментарий",
      dataType: "string",
      validation: { required: false },
    },
    deduction: {
      name: "Сумма вычетов",
      dataType: "number",
      validation: {
        required: false,
      },
    },
    deductionComment: {
      name: "Комментарий к вычету",
      dataType: "string",
      validation: {
        required: false,
      },
    },
    bonus: {
      name: "Премия",
      dataType: "number",
      validation: {
        required: false,
      },
    },
    bonusComment: {
      name: "Комментарий к премии",
      dataType: "string",
      validation: {
        required: false,
      },
    },
  },
});

const buildParentVacancy = (entity: Entity<Employee>) => {
  const parentRef = getParentReferencesFromPath({
    path: entity.path,
    collections: [vacancyCollection],
    currentFullPath: entity.path,
  });
  if (!parentRef[0]?.id) return null;

  return (
    <ReferencePreview
      previewProperties={["title", "employer"]}
      allowEntityNavigation
      size={"small"}
      reference={new EntityReference(parentRef[0]?.id, "vacancies")}
    ></ReferencePreview>
  );
};

export const employeesCollection: any = (group: boolean) => {
  return buildCollection<Employee>({
    name: "Сотрудники",
    singularName: "Сотрудник",
    icon: "AddReaction",
    path: "employees",
    group: "Активность",
    collectionGroup: group,
    // callbacks: employeeRecordsCallbacks,
    permissions: () =>
      // { authController }
      ({
        read: true,
        edit: true,
        create: true,
        delete: true,
      }),
    // subcollections: [
    //     localeCollection
    // ],

    propertiesOrder: [
      "vacancy_ref",
      "employer",
      "vacancy",
      "workerProfile",
      "records",
      "startDate",
      "endDate",
      "status",
      //   "collectionGroupParent",
    ],
    additionalFields: group
      ? [
          {
            id: "vacancy_ref",
            dependencies: ["records"],
            name: "Вакансия",
            width: 250,
            Builder: ({ entity }) => buildParentVacancy(entity),
          },
        ]
      : [],
    properties: {
      employer: {
        name: "Работодатель",
        dataType: "reference",
        path: "user_profiles/clients/profiles",
        // previewProperties: ["firstName", "familyName", "legalForm"],
      },
      vacancy: {
        name: "Вакансия",
        dataType: "reference",
        path: "vacancies",
        // previewProperties: ["firstName", "familyName", "legalForm"],
      },
      workerProfile: {
        name: "Профиль соискателя",
        dataType: "reference",
        path: "user_profiles/applicants/profiles",
        previewProperties: ["firstName", "familyName", "legalForm"],
      },
      status: ({ values }) => ({
        name: "Статус",
        dataType: "string",
        description: "",
        validation: { required: true },
        defaultValue: "accepted",
        enumValues: employeeStatusEnumValues,
      }),
      records: {
        dataType: "array",
        name: "Табель",
        readOnly: group,
        Preview: CustomEmployeeRecordsPreview,
        of: ({ values, index }) => ({
          dataType: "map",
          name:
            (values.records[index!]?.date &&
              new Date(values.records[index!]?.date).toLocaleDateString(
                "ru-RU"
              )) ||
            "Новая запись табеля",
          expanded: false,

          Preview: EmployeeRecordPreview,
          properties: {
            reviewed: {
              name: "Проверен",
              dataType: "boolean",
              validation: {
                required: false,
              },
            },
            paid: {
              name: "Оплачен",
              dataType: "boolean",
              validation: {
                required: false,
              },
            },
            date: {
              name: "Дата",
              dataType: "date",
              mode: "date",
              validation: { required: true, uniqueInArray: true },
            },
            rate: {
              dataType: "number",
              name: "Ставка в час",
              validation: { required: true },
              defaultValue: 1500,
            },
            hours: {
              dataType: "number",
              name: "Количество часов",
              validation: { required: true },
            },
            comment: {
              name: "Комментарий",
              dataType: "string",
              validation: { required: false },
            },
            deduction: {
              name: "Сумма вычетов",
              dataType: "number",
              validation: {
                required: false,
              },
            },
            deductionComment: {
              name: "Комментарий к вычету",
              dataType: "string",
              validation: {
                required: false,
              },
            },
            bonus: {
              name: "Премия",
              dataType: "number",
              validation: {
                required: false,
              },
            },
            bonusComment: {
              name: "Комментарий к премии",
              dataType: "string",
              validation: {
                required: false,
              },
            },
            amount: ({ values }) => ({
              dataType: "number",
              name: "Всего",
              validation: { required: false },
              readOnly: false,
              //   defaultValue: values.rateHour * values.hours,
            }),
          },
        }),

        validation: { required: true },
        defaultValue: [],
      },
      startDate: {
        dataType: "date",
        mode: "date",
        name: "Дата выхода на работу",
        validation: { required: false },
      },
      endDate: {
        dataType: "date",
        mode: "date",
        name: "Дата завершения работы",
        validation: { required: false },
      },
    },
  });
};
