// import React, { ReactElement } from "react";
import { PropertyPreviewProps } from "firecms";

// import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxOutlined from "@mui/icons-material/CheckBoxOutlined";

export  function EmployeeRecordPreview({ value,}: PropertyPreviewProps<Record<string,any>>)
     {

        const date = value.date as Date;
        const displayString = `${date.toISOString().substring(0,10)}`;
    return (
        <div>{displayString}</div>
    );
}


export  function CustomEmployeeRecordsPreview({ value,}: PropertyPreviewProps<Array<Record<string,any>>>)
     {
        if(value && value.length > 0) {
            const date = value[0].date as Date;
            const displayString = `${date.toLocaleDateString('ru-RU')}`;
            return (
                <div>{displayString}</div>
            );
        } else {
            return  (<div>Нет записей</div>)
        }

       
      
   
}