import {
    Box,
} from "@mui/material";

import { Channels } from "./chat/Channels";

/**
 * Sample CMS view not bound to a collection, customizable by the developer
 * @constructor
 */
export function ExampleCMSView() {

    // hook to display custom snackbars
    



   

    return (
        <Box
            display="flex"
            width={"100%"}
            height={"100%"}>


{/* 
            <Paper
                variant={"outlined"}
                sx={{
                    overflow: "hidden",
                    my: 2
                }}> */}
                <Channels></Channels>
            {/* </Paper> */}








        </Box>
    );
}