import { buildCollection, } from "firecms";
// import { Vacancy } from "./vacancies";


export type AdminUser = {
    uid: string,
    email?: string,
    isAdmin?: boolean,
    isSuperadmin?: boolean,
    isModerator?: boolean
}


export const adminUsersCollections = () => buildCollection<AdminUser>({
    name: "Администраторы",
    singularName: "Администратор",
    icon: "Wrench",
    path: 'administrators',
    group: "Администрирование",
    // permissions: (
    //     { authController }
    // ) => {

    //     let allow = false;
    //     try {
    //         const roles: string[] = authController.extra?.roles;
    //         console.log(roles)
    //         allow = roles.includes('superadmin')

    //     } catch (e) {
    //         console.log(e);
    //     }

    //     return ({
    //         read: allow || false,
    //         edit: allow || false,
    //         create: allow || false,
    //         delete: allow || false,
    //     })
    // },
    // subcollections: [
    //     localeCollection
    // ],

    properties: {
        uid: {
            name: "uid",
            dataType: "string",
            readOnly: true,
        },
        email: {
            name: "email",
            dataType: "string",
            // readOnly: true,
        },
        isSuperadmin: {
            name: "Супердминистратор",
            dataType: "boolean",
            // readOnly: true,
        },
        isAdmin: {
            name: "Администратор",
            dataType: "boolean",
            // readOnly: true,
        },
        isModerator: {
            name: "Модератор",
            dataType: "boolean",
            // readOnly: true,
        },
    }
})
