import { EntityCollection, EntityPreview, EntityReference, useEntityFetch } from "firecms";


export interface EntityByReferencePreviewProps {
    reference: EntityReference;
    collection: EntityCollection;
    path: string;
}

export const EntityByReferencePreview: React.FC<EntityByReferencePreviewProps> = ({reference, collection, path}) => {



    const {
        entity,
        dataLoading,
        dataLoadingError
    } = useEntityFetch({
        path: reference.path,
        entityId: reference.id,
        collection,
        useCache: true
    });

    // if (entity) {
    //     referencesCache.set(reference.pathWithId, entity);
    // }
    

    return  entity? <EntityPreview 
        entity={entity}
        collection={collection}
        path={path}
        key={reference.pathWithId}

    /> : <div>Загрузка...</div>
};