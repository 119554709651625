import React, { Fragment } from "react";
import {
  Entity,
  EntityReference,
  useDataSource,
  useReferenceDialog,
} from "firecms"; // Make sure you have useDataSource imported
import { Button, TextField } from "@mui/material";
import { applicantsCollection } from "../ui/collections/applicant";

import { clientsCollection } from "../ui/collections/clients";
import { Channel } from "./domain/entity/channel";
import { channelsCollection } from "./domain/chat-collections";

export type CustomViewProps = {
  onChannelOpened: (channel: Entity<Channel>) => void;
};

export const ProfilePickerView: React.FC<CustomViewProps> = ({
  onChannelOpened,
}) => {
  const dataSource = useDataSource();

  const pickUser = useReferenceDialog({
    collection: applicantsCollection,
    path: "user_profiles/applicants/profiles",
    multiselect: false,
    onSingleEntitySelected(entity) {
      // doc(Firebase, "user_profiles/applicants/profiles", entity?.id).

      dataSource.saveEntity({
        collection: channelsCollection,
        entityId: entity?.id,
        path: "channels",
        status: "new",
        values: {
          displayName: `${entity?.values.firstName} ${entity?.values.familyName}`,
          imageUrl: entity?.values.imageUrl,
          profileRef: new EntityReference(entity!.id, entity!.path),
        },
      });
    },
  });

  const pickClient = useReferenceDialog({
    collection: clientsCollection,
    path: "user_profiles/clients/profiles",
    multiselect: false,
    onSingleEntitySelected(entity) {
      // doc(Firebase, "user_profiles/applicants/profiles", entity?.id).

      if (entity)
        dataSource
          .saveEntity({
            collection: channelsCollection,
            entityId: entity?.id,
            path: "channels",
            status: "new",
            values: {
              displayName: `${entity?.values.firstName} ${entity?.values.familyName}`,
              imageUrl: entity?.values.imageUrl,
              profileRef: new EntityReference(entity!.id, entity!.path),
            },
          })
          .then(() => onChannelOpened(entity!));
    },
  });

  return (
    <Fragment>
      <Button onClick={() => pickUser.open()}>Начать чат с соискателем</Button>
      <Button onClick={() => pickClient.open()}>
        Начать чат с работодателем
      </Button>
      {/* <Autocomplete
        multiple
        id="profile-picker"
        options={filteredOptions}
        getOptionLabel={(profile) =>
          `${profile.firstName} ${profile.familyName}`
        }
        onChange={(_, newValue) => handleProfileChange(newValue)}
        inputValue={searchInput}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField {...params} label="Pick Profiles" variant="outlined" />
        )}
      /> */}
    </Fragment>
  );
};