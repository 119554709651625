import { Entity, EntityReference, buildCollection } from "firecms";
import { Client } from "../../collections/clients";
import { Vacancy } from "../../collections/vacancies";
import { Employee } from "../../collections/employees";
import {
  employeeStatusEnumValues,
  vacancyStatusEnumValues,
} from "../../../domain/enums/enumValues";
import { CustomReferencePreview } from "./CustomReferencePreview";
import { Typography } from "@mui/material";

export const filterByClientColleciton = buildCollection<Partial<Client>>({
  name: "Работодатели",
  path: "user_profiles/clients/profiles",
  textSearchEnabled: false,
  permissions: {
    read: true,
    create: false,
    edit: false,
    delete: false,
  },
  defaultSize: "xs",
  properties: {
    titleShort: {
      name: "Название",
      dataType: "string",
      readOnly: true,
    },
    inn: {
      dataType: "string",
      name: "ИНН",
      readOnly: true,
    },
    phoneNumber: {
      dataType: "string",
      name: "Телефон",
      readOnly: true,
    },
    status: {
      dataType: "string",
      name: "Статус",
      readOnly: true,
      enumValues: {
        moderation: "На модерации",
        active: "Активен",
        blocked: "Заблокирован",
        deleted: "Удален",
      },
    },
  },
});

export const filterByVacancyColleciton = buildCollection<Partial<Vacancy>>({
  name: "Вакансии",
  path: "vacancies",
  textSearchEnabled: false,
  permissions: {
    read: true,
    create: false,
    edit: false,
    delete: false,
  },
  defaultSize: "xs",
  properties: {
    employer: {
      name: "Работодатель",
      dataType: "reference",
      path: "user_profiles/clients/profiles",
    },
    title: {
      name: "Название",
      dataType: "string",
      readOnly: true,
    },
    description: {
      dataType: "string",
      name: "Описание",
      readOnly: true,
    },
    status: {
      dataType: "string",
      name: "Статус",
      readOnly: true,
      enumValues: vacancyStatusEnumValues,
    },
  },
});

export const filterByEmployees = buildCollection<Partial<Employee>>({
  name: "Сотрудники",
  path: "employees",
  collectionGroup: true,
  textSearchEnabled: false,
  permissions: {
    read: true,
    create: false,
    edit: false,
    delete: false,
  },
  defaultSize: "xs",

  propertiesOrder: [
    "employer",
    "vacancy",
    "workerProfile",
    "startDate",
    "endDate",
    "status",
  ],
  properties: {
    workerProfile: {
      name: "Профиль соискателя",
      dataType: "reference",
      path: "user_profiles/applicants/profiles",
      previewProperties: ["firstName", "familyName", "legalForm"],
      Preview: ({ value, property }) => (
        <CustomReferencePreview
          previewProperties={["firstName", "familyName"]}
          size="small"
          allowEntityNavigation={false}
          reference={value}
          renderProperties={(entity, resolvedProperties) => {
            return (
              <Typography variant="body2">{`${entity?.values.firstName} ${entity?.values.familyName}`}</Typography>
            );
          }}
        />
      ),
      Field: ({ value, property }) => (
        <CustomReferencePreview
          previewProperties={["firstName", "familyName"]}
          size="small"
          allowEntityNavigation={false}
          reference={value}
          renderProperties={(entity, resolvedProperties) => {
            return (
              <Typography>{`${entity?.values.firstName} ${entity?.values.familyName}`}</Typography>
            );
          }}
        />
      ),
    },

    startDate: {
      dataType: "date",
      readOnly: true,
      name: "Работает с",
      mode: "date",
    },
    endDate: {
      dataType: "date",
      readOnly: true,
      name: "Работает по",
      mode: "date",
    },
    status: {
      dataType: "string",
      name: "Статус",
      readOnly: true,
      enumValues: employeeStatusEnumValues,
    },
    vacancy: {
      name: "Вакансия",
      dataType: "reference",
      path: "vacancies",
    },
    employer: {
      name: "Работодатель",
      dataType: "reference",
      path: "user_profiles/clients/profiles",
    },
  },
});

export const previewVacancyColleciton = buildCollection<Partial<Vacancy>>({
  name: "Вакансии",
  singularName: "Вакансия",
  path: "vacancies",
  permissions: {
    read: true,
    create: false,
    edit: false,
    delete: false,
  },
  defaultSize: "xs",
  properties: {
    title: {
      name: "Название",
      dataType: "string",
      readOnly: true,
    },
    status: {
      dataType: "string",
      name: "Статус",
      readOnly: true,
      enumValues: vacancyStatusEnumValues,
    },
    startWork: {
      name: "Начало работы",
      dataType: "date",
      mode: "date",
    },
    completionWorks: {
      name: "Окончание работы",
      dataType: "date",
      mode: "date",
    },

    rateHour: {
      name: "Ставка в час",
      dataType: "number",
      validation: { required: true },
    },

    hoursShift: {
      name: "Часов в смене",
      dataType: "number",
      validation: { required: true },
    },

    numberShifts: {
      name: "Кол-во смен",
      dataType: "number",
      validation: { required: true },
    },
    numberEmployees: {
      name: "Требуется сотрудников",
      dataType: "number",
      validation: { required: true },
    },
    scheduleWorks: {
      name: "График работ",
      dataType: "string",
      validation: { required: true },
    },

    employer: {
      name: "Работодатель",
      dataType: "reference",
      path: "user_profiles/clients/profiles",
    },
    description: {
      dataType: "string",
      name: "Описание",
      readOnly: true,
    },
  },
});
