import { buildCollection, EntityReference } from "firecms";
// import { Vacancy } from "./vacancies";


export type CreditCard = {
    cardNumber: string
    active: boolean
    cardType: 'visa' | 'mastercard' | 'mir' | 'other'
    expiryMM: number
    expiryYY: number
    cardHolderName: string
}


export const creditCardsCollection = buildCollection<CreditCard>({
    name: "Карты",
    singularName: "Карта",
    icon: "CurrencyRuble",
    path: "payment_methods",
    permissions: (
        // { authController }
        ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],

    properties: {
        active: {
            name: "Зарплатная",
            dataType: "boolean",
            validation: { required: true }
        },
        cardNumber: {
            name: "Номер карты",
            dataType: "string",
            validation: { required: true },
        },
        cardType: {
            name: "Тип",
            dataType: "string",
            enumValues: {
                visa: "Visa",
                mastercard: "Mastercard",
                mir: "Мир",
                other: "Другая",
            }
        },
        expiryMM: {
            name: "Месяц действия",
            dataType: "number",
            validation: { required: true }
        },
        expiryYY: {
            name: "Год действия",
            dataType: "number",
            validation: { required: true }
        },
        cardHolderName: {
            name: "Имя владельца",
            dataType: "string",
            validation: { required: true }
        },
    }
})
