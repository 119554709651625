import { useCallback } from "react";

// import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { getAuth, connectAuthEmulator } from "firebase/auth";

import {
  connectAuthEmulator,
  getAuth,
  User as FirebaseUser,
} from "firebase/auth";
import logoDark from "./assets/logo_dark.png";
import logoLight from "./assets/logo_light.png";

import {
  Authenticator,
  CMSView,
  EntityCollectionsBuilder,
  // CollectionOverrideHandler,
  // buildCollection,
  // buildProperty,
  // EntityReference,
  FirebaseCMSApp,
  FirebaseSignInProvider,
  // ImagePreview,
  // PropertyBuilder,
  // useInitialiseFirebase
} from "firecms";

import "typeface-rubik";
import "@fontsource/ibm-plex-mono";
import { applicantsCollection } from "./ui/collections/applicant";
import { clientsIpCollection } from "./ui/collections/clients-ip";
import { clientsOrgCollection } from "./ui/collections/clients-org";
import { vacancyCollection } from "./ui/collections/vacancies";
import { billsCollection } from "./ui/collections/bills";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { tabelsCollection } from "./ui/collections/tabels";
import { employeesCollection } from "./ui/collections/employees";
import { applicationsCollection } from "./ui/collections/applications";
import { documentsCollection } from "./ui/collections/documents";
import { ExampleCMSView } from "./Example";
import { businessCategoriesCollection } from "./ui/collections/business-categories";
import { clientsCollection } from "./ui/collections/clients";
import { adminUsersCollections } from "./ui/collections/admin-users";
import TabelsModerationPage from "./ui/pages/components/TabelsModerationPage";
import { ruRU } from "@mui/x-date-pickers";
import { SuperTabelPage } from "./ui/pages/supertabel/SuperTabel";
import { RabotnikCMSApp } from "./RabotnikCMSApp";
import { CustomLogin } from "./cms/login/CustomLogin";

// TODO: Replace with your config
const firebaseConfig = {
  apiKey: "AIzaSyARNDNHdROxyQfKv1M5EYqQW0yhi5HLZjk",
  authDomain: "rabotnik24-954c6.firebaseapp.com",
  projectId: "rabotnik24-954c6",
  storageBucket: "rabotnik24-954c6.appspot.com",
  messagingSenderId: "1098239641380",
  appId: "1:1098239641380:web:81cbca4eddbf75263195b1",
  measurementId: "G-5KVMP685JG",
};

export const baseApiUrl = import.meta.env.VITE_BASE_API_URL;
export default function App() {
  const initEmulator = () => {
    if (false && import.meta.env.DEV) {
      const db = getFirestore();
      connectFirestoreEmulator(db, "localhost", 8080);

      const auth = getAuth();
      connectAuthEmulator(auth, "http://rabotnik24.local:9099");

      const storage = getStorage();
      connectStorageEmulator(storage, "rabotnik24.local", 9199);
    } else {
      // production code
    }
  };

  const myAuthenticator: Authenticator<FirebaseUser> = useCallback(
    async ({ user, authController }) => {
      authController.authLoading = true;

      if (
        user?.email?.includes("yurasopakomlev") ||
        user?.email == "e001kx77@gmail.com"
      ) {
        authController.authError = null;
        authController.authProviderError = null;
        authController.setExtra({ roles: ["superadmin"] });
        console.log("Allowing access to", user?.email);

        authController.authLoading = false;
        return Promise.resolve(true);
      }

      const jwt = await user?.getIdTokenResult(true);

      const claims = jwt?.claims;

      if (!claims) {
        throw Error("Обратитесь к администратору для подтверждения доступа");
      }

      const roles = [];

      if (claims["superadmin"] == true) {
        roles.push("superadmin");
      } else if (claims["admin"] == true) {
        roles.push("admin");
      } else if (claims["moderator"] == true) {
        roles.push("moderator");
      }

      if (roles.length > 0) {
        authController.authError = null;
        authController.authProviderError = null;
        authController.setExtra({ roles: roles });
        authController.authLoading = false;
        console.log("Allowing access to", user?.email, roles);
        return Promise.resolve(true);
      } else {
        throw Error("Обратитесь к администратору для подтверждения доступа");
      }
    },
    [firebaseConfig]
  );

  const customViews: CMSView[] = [
    {
      path: "support_chat",
      name: "Чат поддержки",
      description: "Запросы к службе поддержки",
      // This can be any React component
      view: <ExampleCMSView />,
    },
    {
      path: "tabel-moderation",
      name: "Модерация табелей",
      description: "Модерация табелей",
      view: <TabelsModerationPage />,
    },
    {
      path: "tabel-export",
      name: "Супертабель",
      description: "Экспорт для 1С",
      view: <SuperTabelPage />,
    },
  ];

  const collections:EntityCollectionsBuilder = ()=> [
    applicantsCollection,
    clientsCollection,
    clientsIpCollection,
    clientsOrgCollection,
    vacancyCollection,
    employeesCollection(true),
    applicationsCollection(true),
    adminUsersCollections(),
  ];

  const providers: FirebaseSignInProvider[] = ["password", "google.com"];
  return (
    <RabotnikCMSApp
      // collections= {collections}
      firebaseConfig={firebaseConfig}
      name={`Работник 24 ${import.meta.env.VITE_FLAVOR}`}
      authentication={myAuthenticator}
      collections={collections}
      allowSkipLogin={false}
      // firebaseConfig={firebaseConfig}
      onFirebaseInit={initEmulator}
      secondaryColor="#E7E6E0"
      primaryColor="#EA572B"
      views={customViews}
      LoginView={CustomLogin}
      signInOptions={providers}
      locale={"ru"}
      logo={logoLight}
      logoDark={logoDark}
      fontFamily="Arial"
    />
  );

  // <FirebaseCMSApp
  //     name={`Работник 24 ${import.meta.env.VITE_FLAVOR}`}
  //     authentication={myAuthenticator}
  //     collections={collections}
  //     firebaseConfig={firebaseConfig}
  //     onFirebaseInit={initEmulator}
  //     secondaryColor="#E7E6E0"
  //     primaryColor="#EA572B"
  //     views={customViews}
  //     signInOptions={providers}
  //     locale={'ru'}
  //     logo={logoLight}
  //     logoDark={logoDark}
  //     fontFamily="Arial"
  // />;
}
