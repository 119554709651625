import { Timestamp } from "firebase/firestore"
import { buildCollection, buildEntityCallbacks, buildProperties, buildPropertiesOrBuilder, buildProperty, CollectionActionsProps, PropertiesOrBuilders, PropertyBuilder, PropertyBuilderProps } from "firecms"
import { Link } from "react-router-dom";

import { baseApiUrl } from "../../App"
import { ClientIp } from "./clients-ip";
import { ClientOrganization } from "./clients-org"
import { clientFeedbacksCollections } from "./feedbacks";
import { currentBillsCollection } from "./employer-bills";
import { addIPDocuments, addOrganizationDocuments, buildPassport } from "./documents";
import { SinglePersonChat } from "../../chat/SinglePersonChat";



export type Client = ClientOrganization | ClientIp;
type Entry<T> = [string, T];

function isEntryNullable<T>(entry: Entry<T | null | undefined>): entry is Entry<T | null | undefined> {
    console.log(entry[0], typeof entry[1])
    return entry[1] === null || entry[1] === undefined || entry[1] == '';
}


const clientCallbacks = buildEntityCallbacks<Client>({

    onPreSave: async ({
        // collection,
        // path,
        // entityId,
        values,
        status,

    }) => {


        const properties = Object.entries(values);

        for (const entry of properties) {
            if (isEntryNullable(entry)) {
                if (entry[1] == '') {
                    (values as any)[entry[0]] = undefined
                }
            } else {
                console.log(`Entry ${entry[0]} is not nullable`);
            }
        }

        // return the updated values
        if (status == "new") {
            console.log("status is new")
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber: values.phoneNumber })
            };
            let data = await fetch(`${baseApiUrl}/user`, requestOptions)
                .then(response => response.json())

            console.log("createduser")
            console.log(data)

            values.userId = data.uid


            // .then(data => this.setState({ postId: data.id }));
            return values;
        } else {
            return values;
        }

    },
    async onSaveSuccess({ status, values, entityId }) {
        if (status == "new") {
            console.log("Linking user with profile")
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: values.userId,
                    profileId: entityId,
                    userRole: values.userRole,
                    legalForm: values.legalForm,
                })
            };
            let data = await fetch(`${baseApiUrl}/user/linkProfile`,
                requestOptions,
            )

            console.log("linked user")
            console.log(data)
        }
    },
    // onIdUpdate(idUpdateProps) {
    //     console.log("onIdUpdate");
    //     console.log(idUpdateProps.entityId)
    //     return idUpdateProps.entityId || '';
    // },

});


// export const clientsBuilder = buildPropertiesOrBuilder<ClientIp|ClientOrganization>({
// );





export const clientsCollection = buildCollection<Client>({
    name: "Работодатели",
    singularName: "Работодатель",
    icon: "BusinessCenter",
    path: "user_profiles/clients/profiles",
    callbacks: clientCallbacks,
    group: "Профили пользователей",
    alias: 'clients-all',
    views: [
        {
             name: "Чат",
             path: "chat",
      Builder: ({ entity }) => {
 
        return ( <SinglePersonChat
        userEntity={entity!}
        userId={entity!.id}

       
        
        />)
        
      },
        }
     ],
    Actions: [({ }: CollectionActionsProps<Client>) =>
        <Link to={'/c/clients-org'}>
            {'Организации'}
        </Link>,

    ({ }: CollectionActionsProps<Client>) =>
        <Link to={'/c/clients-ip'}>
            {'ИП'}
        </Link>],
    permissions: (
        // { authController }
    ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],
    subcollections: [currentBillsCollection, clientFeedbacksCollections(false)],
    properties: buildProperties<Client>({

        titleShort: ({ values }: PropertyBuilderProps<ClientOrganization>) => buildProperty({
            name: "Сокращенное наименование",
            dataType: "string",
            disabled: values.legalForm == 'ip',
            validation: { required: values.legalForm == 'organization' }
        }),
        legalForm: {
            name: "Тип",
            dataType: "string",
            validation: { required: true },
            readOnly: true,
            defaultValue: "ip",
            enumValues: {
                'ip': "ИП",
                'organization': 'Организация'
            }
        },
        status: {
            name: "Статус профиля",
            dataType: "string",
            validation: { required: true },
            enumValues: {
                moderation: "На модерации",
                active: "Активен",
                blocked: "Заблокирован",
                deleted: "Удален"
            }
        },

        inn: (props: PropertyBuilderProps<Client>) => buildProperty({
            name: 'ИНН',
            dataType: 'string',
            validation: {
                min: props.values.legalForm == 'ip' ? 12 : 10,
                max: props.values.legalForm == 'ip' ? 12 : 10,
            }
        }),
        // @IsString()
        titleFull: ({ values }: PropertyBuilderProps<ClientOrganization>) => buildProperty({
            name: "Полное наименование",
            dataType: "string",
            validation: { required: values.legalForm == 'ip' }
        }),
        phoneNumber: {
            name: "Номер телефона",
            validation: { required: false },
            dataType: "string",
        },

        email: {
            name: "EMail",
            dataType: "string",
            validation: {
                required: true,
            },
            email: true,
        },

        contactPerson: {
            name: "Контактное лицо",
            dataType: "string",
            validation: { required: true }
        },

        userRole: {
            name: "Роль",
            dataType: "string",
            validation: { required: true },
            readOnly: true,
            defaultValue: "client",
            enumValues: {
                "client": "Работодатель"
            },
            hideFromCollection: true,
        },

    


        // @IsNumberString({no_symbols: true})
        // @Length(12,12)

   
        // titleFull: {
        //     name: "Полное наименование",
        //     dataType: "string",
        //     validation: { required: true }
        // },

        // @IsEnum(TaxScheme) 
        // @IsOptional()
        taxScheme: {
            name: "Форма налогообложения",
            dataType: "string",
            validation: { required: true },
            enumValues: { USN: "УСН", OSNO: "ОСНО" }
        },
        // @IsOptional()
        // @IsDate()
        registrationDate: {
            name: "Дата регистрации",
            dataType: "date",
            validation: { required: false },
            mode: "date",
        },
        // @IsOptional()
        // @IsString()
        registrationAddress: {
            name: "Адрес регистрации",
            validation: { required: false },
            dataType: "string",
        },
        // @IsOptional()
        // @IsNumberString({no_symbols: true})
        // @Length(13,13)
        ogrn: ({ }: PropertyBuilderProps<Client>) => buildProperty({
            name: "ОГРН",
            dataType: "string",
            validation: { required: false, min: 13, max: 13 },
        }),
        // @IsOptional()
        // @IsDateString()
        ogrnDate: {
            name: "Дата ОГРН",
            dataType: "date",
            validation: { required: false },
            mode: "date",
        },
        // @IsOptional()
        // @IsNumberString({no_symbols: true})
        // @Length(9,9)
        bic: {
            name: "БИК",
            dataType: "string",
            validation: { required: false, min: 9, max: 9 },
        },
        // @IsOptional()
        // @IsString()
        bankName: {
            name: "Наименование банка",
            dataType: "string",
            validation: { required: false, },
        },
        // @IsOptional()
        // @IsNumberString({no_symbols: true})
        // @Length(20,20)
        currentAccountNumber: {
            name: "Расчетный счет",
            dataType: "string",
            validation: { required: false, min: 20, max: 20 },
        },
        // @IsOptional()
        // @IsNumberString({no_symbols: true})
        // @Length(20,20)
        corrAccount: {
            name: "Корр. счет",
            dataType: "string",
            validation: { required: false, min: 20, max: 20 },
        },

        ceoName: ({ values }: PropertyBuilderProps<Client>) => buildProperty({
            name: "Ген. Дир",
            dataType: "string",
            disabled: values.legalForm == 'ip',
            validation: { required: false, },
        }),
        // @IsOptional()
        // @IsUrl()
        imageUrl: {
            name: "Изображение профиля",
            validation: {
                required: false,
            },
            dataType: "string",
            storage: {
                fileName: `avatar_client_{entityId}_${Timestamp.now().seconds}.{file.ext}`,
                storagePath: `profile/{entityId}/avatar/`,
                acceptedFiles: ["image/*"],
                metadata: {
                    cacheControl: "max-age=1000000"
                }
            }
        },
        userId: {
            name: 'userId',
            dataType: "string",
            hideFromCollection: true,
            disabled: true,
            // editable: false,
            readOnly: true,
        },

        documents: ({ values }: PropertyBuilderProps<Client>) => {


            const mapProperties = buildProperties<Record<string, any>>({});


            if (values.legalForm == 'ip') {
                // const passportProperties =;

                mapProperties['passport'] = buildProperty<any>({
                    dataType: "map",
                    name: "Паспорт",
                    properties:  buildPassport(true),
                });
                addIPDocuments(mapProperties);
            } else {
                addOrganizationDocuments(mapProperties);
            }

            return buildProperty({
                name: "Документы",
                dataType: "map",
                validation: { required: false, },
                properties: mapProperties,
            })
        },
        // надежный / ннеадежный клиент
        trusted: {
            name: 'Надежный клиент',
            dataType: 'boolean',
            defaultValue: false,
        },
        creditLimit: ({ values }: PropertyBuilderProps<Client>) => buildProperty({
            name: "Кредитный лимит",
            dataType: "number",
            disabled: !values.trusted,
            validation: { required: values.trusted, positive: true },
        }),
        postpaid: ({ values }: PropertyBuilderProps<Client>) => buildProperty({
            name: "Отсрочка (постоплата)",
            dataType: "boolean",
            defaultValue: false,
            validation: { required: values.trusted },
            disabled: !values.trusted,
        }),
        // комиссия пользования сервисом*
        comission: ({
            name: "Комиссия (коэффициент)",
            dataType: "number",
            validation: { required: true, positive: true },
            defaultValue: 2.21,

        }),
        rating: {
            name: "Рейтинг",
            dataType: "number",
            defaultValue: 0,
            validation: {
                min: 0,
                max: 5,
            },
        },
        reviewsCount: {
            name: "Отзывы",
            dataType: "number",
            defaultValue: 0,
            validation: {
                min: 0,
                integer: true,
            },
        },
    })


}

);