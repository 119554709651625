import {
  StorageSource,
  DownloadConfig,
  UploadFileProps,
  UploadFileResult,
  DownloadMetadata,
} from "firecms";

import { HeadObjectCommand, PutObjectCommandInput, S3, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";


import { useEffect, useState } from "react";

export interface S3StaticCredentials {
  accessKeyId: string;
  secretAccessKey: string;
}

/**
 * @category Yandex Object Storage
 */
export interface S3StorageSourceProps {
  region: string;
  endpoint: string;
  credentials: S3StaticCredentials;
  bucket: string;
}

const getS3Storage = (
  region: string,
  endpoint: string,
  credentials: S3StaticCredentials
) => {
  const s3Client = new S3({
    region,
    endpoint,
    credentials,
  });
  return s3Client;
};

/**
 * Use this hook to build an {@link StorageSource} based on Firebase storage
 * @category Firebase
 */
export function useS3StorageSource({
  region,
  endpoint,
  credentials,
  bucket,
}: S3StorageSourceProps): StorageSource {
  
  const [client, setClient] = useState<S3>();

  useEffect(() => {
    if (!credentials) return;

    const storageClient =  new S3({
        region,
        endpoint,
        credentials,
      });
    setClient(storageClient); 
    // setClient(getS3Storage(region, endpoint, credentials));
  }, [region, credentials.accessKeyId, credentials.secretAccessKey]);

  const urlsCache: Record<string, DownloadConfig> = {};

  const generateDownloadUrl: (params: any) => Promise<string> = async (
    params
  ) => {
    const command = new GetObjectCommand(params);
    const url = await getSignedUrl(client!, command, { expiresIn: 3600 });
    return url;
  };

  const getMetadata = async (storagePathOrUrl: string) => {
     // Retrieve metadata using headObject
  const metadataParams = {
    Bucket: bucket,
    Key: storagePathOrUrl,
  };
  const metadataResponse = await client!.send(new HeadObjectCommand(metadataParams));
  const meta: DownloadMetadata =  {
    bucket,
    contentType: metadataResponse.ContentType ?? 'application/octet-stream',
    fullPath: `${bucket}/${storagePathOrUrl}`,
    size: metadataResponse.ContentLength ?? 0,
    name: storagePathOrUrl.split("/").pop()!,
    customMetadata: {}
  };

  return meta;

  }

  return {
    uploadFile({
      file,
      fileName,
      path,
      metadata,
    }: UploadFileProps): Promise<UploadFileResult> {
      if (!client) throw Error("useS3StorageSource S3Client not initialised");
      const usedFilename = fileName ?? file.name;
      console.debug("Uploading file", usedFilename, file, path, metadata);
      const params: PutObjectCommandInput = {
        Bucket: bucket,
        Key: `${path}/${usedFilename}`,
        Body: file,
        Metadata: metadata,
        ContentType: file.type,
      };
      return client.putObject(params).then(({ $metadata }) => ({
        path: params.Key!,
      }));
    },

    async getFile(path: string): Promise<File | null> {
      try {
        if (!client) throw Error("useS3StorageSource Client not initialised");

        const params = {
          Bucket: bucket,
          Key: path,
        };

        const url = await generateDownloadUrl(params);
        const response = await fetch(url);
        const blob = await response.blob();
        return new File([blob], path);
      } catch (e: any) {
        if (e?.code === "storage/object-not-found") return null;
        throw e;
      }
    },

    async getDownloadURL(storagePathOrUrl: string): Promise<DownloadConfig> {
      if (!client) throw Error("useS3StorageSource Client not initialised");
      if (urlsCache[storagePathOrUrl]) return urlsCache[storagePathOrUrl];

      const params = {
        Bucket: bucket,
        Key: storagePathOrUrl,
      };
      const url = await generateDownloadUrl(params);

      const metadata = await getMetadata(storagePathOrUrl);
      // const [url, metadata] = await Promise.all([getDownloadURL(fileRef), getMetadata(fileRef)]);
      const result: DownloadConfig = {
        url,
        metadata,
      };

      urlsCache[storagePathOrUrl] = result;
      return result;
    },
  };
}

