import * as React from "react";
import { useMemo } from "react";

import {
    Box,
    darken,
    IconButton,
    lighten,
    Skeleton,
    Tooltip,
    Typography
} from "@mui/material";



import { t } from "i18next";
import { from } from "rxjs";
import { Entity, EntityCollection, EntityReference, ErrorView, getReferencePreviewKeys, getValueInPath, PropertyPreview, ReferenceProperty, resolveCollection, ResolvedProperty, SkeletonPropertyComponent, useEntityFetch, useFireCMSContext, useNavigationContext, useSideEntityController } from "firecms";
import { PreviewSize } from "firecms";
import { fieldBackground, fieldBackgroundHover } from "../../../../../lib/src/core/util/field_colors";


/// passed to renderer funciton (as an array item representing each resolved property)
export type RenderableProperty= {childProperty: ReferenceProperty, key: string};


export type ReferencePreviewProps = {
    disabled?: boolean;
    reference: EntityReference,
    size: PreviewSize;
    previewProperties?: string[];
    onClick?: () => void;
    onHover?: boolean;
    allowEntityNavigation?: boolean;
    renderProperties: (entity: Entity<any>|undefined, properties: {childProperty: ReferenceProperty, key: string}[]) => React.ReactNode
};

/**
 * @category Preview components
 */
export const CustomReferencePreview = React.memo<ReferencePreviewProps>(ReferencePreviewInternal, areEqual) as React.FunctionComponent<ReferencePreviewProps>;

function areEqual(prevProps: ReferencePreviewProps, nextProps: ReferencePreviewProps) {
    return prevProps.disabled === nextProps.disabled &&
        prevProps.size === nextProps.size &&
        prevProps.onHover === nextProps.onHover &&
        prevProps.reference?.id === nextProps.reference?.id &&
        prevProps.reference?.path === nextProps.reference?.path &&
        prevProps.allowEntityNavigation === nextProps.allowEntityNavigation
        ;
}

function ReferencePreviewInternal<M extends Record<string, any>>({
                                                                     disabled,
                                                                     reference,
                                                                     previewProperties,
                                                                     size,
                                                                     onHover,
                                                                     onClick,
                                                                     allowEntityNavigation = true,
                                                                     renderProperties
                                                                 }: ReferencePreviewProps) {

    const context = useFireCMSContext();

    const navigationContext = useNavigationContext();
    const sideEntityController = useSideEntityController();

    const collection = navigationContext.getCollection<EntityCollection<M>>(reference.path);
    if (!collection) {
        throw Error(`Couldn't find the corresponding collection view for the path: ${reference.path}`);
    }

    const {
        entity,
        dataLoading,
        dataLoadingError
    } = useEntityFetch({
        path: reference.path,
        entityId: reference.id,
        collection,
        useCache: true
    });

    if (entity) {
        referencesCache.set(reference.pathWithId, entity);
    }

    const usedEntity = entity ?? referencesCache.get(reference.pathWithId);

    const resolvedCollection = useMemo(() => resolveCollection({
        collection,
        path: reference.path,
        values: usedEntity?.values,
        fields: context.fields
    }), [collection]);

    const listProperties = useMemo(() => getReferencePreviewKeys(resolvedCollection, context.fields, previewProperties, size === "small" || size === "regular" ? 3 : 1),
        [previewProperties, resolvedCollection, size]);

    let body: React.ReactNode;

    if (!resolvedCollection) {
        return <ErrorView
            error={"Could not find collection with id " + resolvedCollection}/>
    }

    if (!reference) {
        body = <ErrorView error={"Reference not set"}/>;
    } else if (!(reference instanceof EntityReference)) {
        console.error("Reference preview received value of type", typeof reference);
        body = <ErrorView error={t("Unexpected value")||"Unexpected value"}
                          tooltip={JSON.stringify(reference)}/>;
    } else if (usedEntity && !usedEntity.values) {
        body = <ErrorView error={t("Reference does not exist")||"Reference does not exist"}
                          tooltip={reference.path}/>;
    } else {
        body = (
            <>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: 1,
                    width: "100%",
                    maxWidth: "calc(100% - 52px)",
                    margin: 0
                }}>

                    {/* {size !== "tiny" && (
                        reference
                            ? <Box sx={{
                                display: size !== "regular" ? "block" : undefined,
                                whiteSpace: size !== "regular" ? "nowrap" : undefined,
                                overflow: size !== "regular" ? "hidden" : undefined,
                                textOverflow: size !== "regular" ? "ellipsis" : undefined
                            }}>
                                <Typography variant={"caption"}
                                            className={"mono"}>
                                    {reference.id}
                                </Typography>
                            </Box>
                            : <Skeleton variant="text"/>)} */}

                    {   listProperties  
                    ?  renderProperties(usedEntity,listProperties.map<{childProperty: ResolvedProperty|null, key: string}>((key) => {
                        const childProperty = resolvedCollection.properties[key as string] || null;
                        return {childProperty, key: key as string};
                    }).filter(({childProperty}) => childProperty!=null).map<RenderableProperty>((property) => property as RenderableProperty)                    
                    
                    ): <Skeleton variant="text"/>}

                        
                        
                        {/* return ( */}


                            {/* <Typography>{usedEntity? `${getValueInPath(usedEntity.values, key)}` : 'Не получилось загрузить'}</Typography>
                            // <Box key={"ref_prev_" + (key as string)}
                            //      sx={{
                            //          my: listProperties.length > 1 ? 0.5 : 0
                            //      }}>
                            //     {usedEntity
                            //         ? <PropertyPreview
                            //             propertyKey={key as string}
                            //             value={getValueInPath(usedEntity.values, key)}
                            //             property={childProperty as ResolvedProperty}
                            //             entity={usedEntity}
                            //             size={"tiny"}/>
                            //         // eslint-disable-next-line react/jsx-no-undef
                            //         : <SkeletonPropertyComponent
                            //             property={childProperty as ResolvedProperty}
                            //             size={"tiny"}/>
                            //     }
                            // </Box> */}
                        
                    

                </Box>
 
            </>
        );
    }

    return (
        <ReferencePreviewWrap onClick={disabled ? undefined : onClick}
                              onHover={disabled ? undefined : onHover}
                              size={size}>
            {body}
        </ReferencePreviewWrap>
    );
}

function ReferencePreviewWrap({
                                  children,
                                  onHover,
                                  size,
                                  onClick
                              }: {
    children: React.ReactNode;
    onHover?: boolean;
    size: PreviewSize;
    onClick?: () => void;
}) {
    return <Typography variant={"label"}
                       sx={(theme) => {
                           const clickableStyles = onClick
                               ? {
                                   tabindex: 0,
                                   backgroundColor: onHover
                                       ? fieldBackgroundHover(theme)
                                       : fieldBackground(theme),
                                   transition: "background-color 300ms ease, box-shadow 300ms ease",
                                   boxShadow: onHover ? "0 0 0 2px rgba(128,128,128,0.05)" : undefined,
                                   cursor: onHover ? "pointer" : undefined
                               }
                               : {};
                           return ({
                               width: "100%",
                               display: "flex",
                               borderRadius: `${theme.shape.borderRadius}px`,
                               overflow: "hidden",
                               padding: size === "regular" ? 1 : 0,
                               itemsAlign: size === "tiny" ? "center" : undefined,
                               ...clickableStyles
                           });
                       }}
                       onClick={(event) => {
                           if (onClick) {
                               event.preventDefault();
                               onClick();
                           }
                       }}>

        {children}

    </Typography>
}

const referencesCache = new Map<string, Entity<any>>();


export type CustomReferenceFieldProps = {
    disabled?: boolean;
    reference: EntityReference,
    size: PreviewSize;
    previewProperties?: string[];
    onClick?: () => void;
    onHover?: boolean;
    allowEntityNavigation?: boolean;
};

// const CustomReferenceField = React.memo<CustomReferenceFieldProps>(ReferencePreviewInternal, areEqual) as React.FunctionComponent<CustomReferenceFieldProps>;