import { Typography } from "@mui/material";
import { buildCollection, EntityReference } from "firecms";
// import { Vacancy } from "./vacancies";


export type Application = {
    vacancyId: EntityReference
    applicantId: EntityReference
    createdAt: Date,
    status: string,
    resolutionDate?: Date
}




export const applicationsCollection = (group: boolean) => buildCollection<Application>({
    name: "Отклики",
    singularName: "Отклик",
    icon: "AssignmentTurnedInIcon",
    path: "applications",
    group: "Активность",
    collectionGroup: group,
    permissions: (
        // { authController }
    ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],
    properties: {
        vacancyId: ({ path }) => {

            const pathParts = path.split('/');
            pathParts.pop();
            const parentId = pathParts.pop();
            const parentPath = pathParts.join('/');

            return {
                name: "Вакансия",
                dataType: "reference",
                path: "vacancies",
                previewProperties: ["title", "description", "status"],
                validation: { required: group },
                readOnly: !group,
                defaultValue: group ? undefined : new EntityReference(parentId!, parentPath),
            }
        },
        applicantId: {
            name: "Работник",
            dataType: "reference",
            path: "user_profiles/applicants/profiles",
            previewProperties: ["firstName", "familyName", "id"],
            validation: { required: true }
        },
        createdAt: {
            name: "Дата отклика",
            dataType: "date",
            validation: { required: false },
            autoValue: 'on_create',
        },
        resolutionDate: {
            name: "Дата решения",
            dataType: "date",
            validation: { required: false },
            // autoValue: 'on_create',
        },
        status: {
            name: 'Статус',
            dataType: 'string',
            defaultValue: "applied",
            enumValues: {
                "invited": "Приглашение отправлено",
                "applied": "Отклик ожидает ответа",
                "accepted": "Подтвержден",
                "rejected": "Отклонен работодателем",
                "invitationDeclined": "Приглашене отклонено",
            }
        }
    }
})
