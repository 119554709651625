import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
//@ts-ignore
import { VariableSizeList as List } from "react-window";

const rowHeight = 48;

export interface VirtualSuperTableProps {
  data: any[];
  headers: { label: string; width: number }[];
}
const VirtualSuperTable: React.FC<VirtualSuperTableProps> = ({
  data,
  headers,
}) => {
  const itemData = { data, headers };

  const getColumnWidth = (index: number) => headers[index].width;
  return (
    <Paper>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headers.map((column, index) => (
                <TableCell key={index} style={{ width: getColumnWidth(index) }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

          {data.map((rowData, rowIndex) => (
              <TableRow key={rowIndex}>
                {headers.map((column, columnIndex) => (
                  <TableCell key={columnIndex}>{`${rowData[column.label]}`}</TableCell>
                ))}
              </TableRow>
            ))}


          {/* <List
              height={400}
              itemCount={data.length}
              itemSize={(n:number)=>rowHeight}
              width={500}
              itemData={{ data, headers }}
            >
              {({ index }:any) => {
                const rowData = data[index];
                return (
                  <TableRow  key={index}>
                    {headers.map((column, columnIndex) => (
                      <TableCell key={columnIndex}>{rowData[column.label]}</TableCell>
                    ))}
                  </TableRow>
                );
              }}
            </List> */}
            {/* {headers.map(label, index) => {
                const rowData = data[index];

              return (
                <TableRow key={index}>
                  {data.map((column, columnIndex) => (
                    <TableCell key={columnIndex}>{rowData?.[columnIndex]?.toString() || ""}</TableCell>
                  ))}
                </TableRow>
              );
            } */}
            
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default VirtualSuperTable;
