import React from 'react';
import { Button } from '@mui/material';

import Papa from 'papaparse';

function convertToCSV(data: any[]) {
  const csv = Papa.unparse(data);
  return csv;
}

function DownloadCSVButton({ data }: any) {
  const downloadCSV = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.csv';
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button disabled={!data || data.length<=0} variant="contained" onClick={downloadCSV}>
      Скачать CSV
    </Button>
  );
}

export default DownloadCSVButton;
