import { Button } from "@mui/material";
import { FirebaseLoginView, FirebaseLoginViewProps } from "firecms";

export function CustomLogin(props: FirebaseLoginViewProps) {
    let customProps = { ...props };
    const roles = props.authController.extra?.roles;
  
    let allowSignUp = roles?.includes('created') ||roles?.includes('moderator') || roles?.includes('admin') || roles?.includes('superadmin');
  
    customProps.disableSignupScreen = true;
  
    const email = customProps.authController.user?.email;

    if ( email?.includes("yurasopakomlev") || email == "e001kx77@gmail.com") {
      customProps.notAllowedError = undefined;
    }
  
    if (props.authController.user && !props.authController.user?.emailVerified) {
      customProps.notAllowedError = 'Please verify your email address';
      customProps.disableSignupScreen = !allowSignUp;
    }
  
    if (customProps.notAllowedError) {
      customProps.disabled = true;
      customProps.signInOptions = [];
  
      customProps.additionalComponent = (
        <Button
          variant="outlined"
          onClick={async () => {
            customProps.authController.signOut();
            customProps.authController.authError = undefined;
            customProps.authController.authProviderError = undefined;
            window.location.reload();
          }}
        >
          Назад
        </Button>
      );
      return FirebaseLoginView(customProps);
    }
  
    if (customProps.authController.authError || customProps.authController.authLoading) {
      customProps.disabled = true;
      customProps.signInOptions = [];
      customProps.additionalComponent = (
        <Button
          variant="outlined"
          onClick={async () => {
            customProps.authController.signOut();
            customProps.authController.authError = undefined;
            customProps.authController.authProviderError = undefined;
          }}
        >
          Назад
        </Button>
      );
    } else {
      customProps.disabled = false;
    }
  
    return FirebaseLoginView(customProps);
  }
  