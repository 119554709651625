import { buildCollection } from "firecms";
import { ChatMessage } from "./entity/message";
import { Profile } from "./entity/profile";
import { Channel } from "./entity/channel";

export const profilesCollection = buildCollection<Profile>({
    name: "profiles",
    path: "profiles",
    collectionGroup: true,
    // properties: {
    //     // imageUrl: {
    //     //     dataType: 'string'
    //     // },

    // }
    properties: {
      imageUrl: {
        dataType: "string",
      },
    },
  });

  export const messagesCollection = buildCollection<ChatMessage>({
    name: "message",
    path: "messages",
    properties: {
      support: {
        dataType: "boolean",
      },
      timestamp: {
        dataType: "date",
      },
      message: {
        dataType: "string",
      },
      attachments: {
        dataType: "array",
        of: {
          dataType: "map",
          properties: {
            name: {
              dataType: "string",
            },
            path: {
              dataType: "string",
            },
            type: {
              dataType: "string",
            },
          },
        },
      },
    },
  });



  export const channelsCollection = buildCollection<Channel>({
    name: "channels",
    path: "channels",
    properties: {
      displayName: {
        dataType: "string",
      },
      unread: {
        dataType: "number",
      },
      imagePath: {
        dataType: "string",
      },
      lastMessage: {
        dataType: "map",
        properties: {
          message: {
            dataType: "string",
          },
          support: {
            dataType: "boolean",
          },
        },
      },
      profileRef: {
        dataType: "reference",
      },
      legalForm: {
        dataType: "string",
      },
      userRole: {
        dataType: "string",
      },
      imageUrl: {
        dataType: "string",
      },
    },
  });