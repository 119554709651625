import { buildCollection, EntityReference } from "firecms";
// import { Vacancy } from "./vacancies";


export type ClientFeedback = {
    rating: number,
    name: string,
    message: string,
    date: Date,
    reviewedBy: EntityReference
}


export const clientFeedbacksCollections =(isGroup: boolean) => buildCollection<ClientFeedback>({
    name: "Отзывы о клиенте",
    singularName: "Отзыв",
    icon: "StarRating",
    collectionGroup: isGroup,
    path: "client-feedback",
    permissions: (
        // { authController }
        ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],

    properties: {
        rating: {
            name: "Оценка",
            dataType: "number",
            validation: { required: true, integer: true},
        },
        name: {
            name: "Заголовок",
            dataType: "string",
        },
        message: {
            name: "Текст отзыва",
            dataType: "string",
            validation: { required: true }
        },
        date: {
            name: "Дата отзыва",
            dataType: "date",
            validation: { required: true }
        },
        reviewedBy: {
            name: "Пользователь",
            dataType: "reference",
            path: "user_profiles/applicants/profiles",
            validation: { required: true },
            previewProperties: ['firstName', 'familyName']
        },
    }
})

export type ApplicantFeedback = {
    rating: number,
    title: string,
    message: string,
    date: Date,
    employerRef: EntityReference,
    vacancyRef: EntityReference,
    vacancyName: string,
    employerName: string,
}


export const applicantFeedbacksCollections =(isGroup: boolean) => buildCollection<ApplicantFeedback>({
    name: "Отзывы о соискателе",
    singularName: "Отзыв",
    icon: "StarRating",
    collectionGroup: isGroup,
    path: "feedbacks",
    permissions: (
        // { authController }
        ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],

    properties: {
        rating: {
            name: "Оценка",
            dataType: "number",
            validation: { required: true, integer: true},
        },
        vacancyName: {
            name: "Вакансия",
            dataType: "string",
        },
        employerName: {
            name: "Работодатель",
            dataType: "string",
        },
        title: {
            name: "Заголовок",
            dataType: "string",
        },
        message: {
            name: "Текст отзыва",
            dataType: "string",
            validation: { required: true }
        },
        date: {
            name: "Дата отзыва",
            dataType: "date",
            validation: { required: true }
        },
        vacancyRef: {
            name: "Ссылка на Вакансию",
            dataType: "reference",
            path: "vacancies",
            validation: { required: true },
        },
        employerRef: {
            name: "Профиль Работодателя",
            dataType: "reference",
            path: "user_profiles/clients/profiles",
            validation: { required: true },
            // previewProperties: ['titleFull']
        },
    }
})
