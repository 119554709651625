import { buildCollection, buildEnumValues, buildProperties, buildProperty, PropertiesOrBuilders, } from "firecms";
import { citizenshipBuilder } from "../builders/enum_builders";





export type Documents = {
    // id: string
    legalForm: string,
    userRole: string,
    citizenship: string,
    documents: object,
}



// export declare class LocationDetailsClass {
//     constructor(latitude: number, vacancyAddress: string, longitude: number);


//     /**
//      * The latitude of this GeoPoint instance.
//      */
//      readonly latitude: number;
//      readonly vacancyAddress: number;

//      /**
//       * The longitude of this GeoPoint instance.
//       */
//      readonly longitude: number;
// }



export const buildAttachments = () => buildProperty({
    name: "Загрузки",
    dataType: "map",
    properties: {
        files: {
            name: "Файлы",
            dataType: "array",
            of: {
                dataType: "map",
                name: "Загрузка",
                validation: { required: false },
                properties: {
                    url: {
                        name: "Файл",
                        dataType: "string",
                        storage: {
                            storagePath: "documents",
                            storeUrl: false,
                            acceptedFiles: ["image/*", "application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
                            // maxSize: 1024 * 1024,
                            metadata: {
                                cacheControl: "max-age=1000000"
                            },
                            // storeUrl: true,
                        },
                    }
                }
            }
        }
    }

});

export const addOrganizationDocuments = (properties: PropertiesOrBuilders) => {
    /// выписка из егрюл
    properties['egrulCertificate'] = buildProperty<any>({
        name: "Выписка из ЕГРЮЛ",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })

    /// свидетельство огрн
    properties['ogrnCertificateCopy'] = buildProperty<any>({
        name: "Свидетельство ОГРН",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })

    // Свид-во о постановке на налоговый учет
    properties['taxRegistrationCertificate'] = buildProperty<any>({
        name: "Свид-во о постановке на налоговый учет",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })

    /// устав организации
    properties['organizationStatute'] = buildProperty<any>({
        name: "Устав организации",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })



    //     // /// Скан/фото копии Приказа о назначении руководителя/генерального директора
    properties['ceoAssignmentActCopy'] = buildProperty<any>({
        name: "Приказ о назначении руководителя/генерального директора",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })

    // Скан/фото копии Решения участников о назначении руководителя/генерального директора
    properties['ceoAssignmentAgreement'] = buildProperty<any>({
        name: "Решениe участников о назначении руководителя/генерального директора",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })

    //     // /// Скан/фото копии Доверенности о полномочиях представителя (если подписант - не ген директор)
    properties['representativePowerNote'] = buildProperty<any>({
        name: "Доверенность о полномочиях представителя (если подписант - не ген директор)",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })
}




export const addIPDocuments = (properties: PropertiesOrBuilders) => {


    /// свидетельство огрн
    properties['ogrnCertificateCopy'] = buildProperty<any>({
        name: "Свидетельство ОГРН",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })


    // Свид-во о постановке на налоговый учет
    properties['taxRegistrationCertificate'] = buildProperty<any>({
        name: "Свид-во о постановке на налоговый учет",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })

    //     // /// Скан/фото копии Доверенности о полномочиях представителя (если подписант - не ген директор)
    properties['representativePowerNote'] = buildProperty<any>({
        name: "Доверенность о полномочиях представителя (если подписант - не ген директор)",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })



    /// выписка из егрип
    properties['egripCertificate'] = buildProperty<any>({
        name: "Выписка из ЕГРЮЛ",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })


    /// выписка из егрип
    properties['certificateOfBankAccount'] = buildProperty<any>({
        name: "Справка об открытии расетного счета",
        dataType: "map",
        properties: {
            attachment: buildAttachments()
        }
    })

}


//   const factory EmployerDocuments.ip({
//     /// выписка из егрюл
//     @Default(IpDocPassport()) IpDocPassport passport,

//     /// свидетельство огрн
//     @Default(IpDocOgrn()) IpDocOgrn ogrnCertificateCopy,

//     /// Свид-во о постановке на налоговый учет
//     @Default(IpDocTax()) IpDocTax  taxRegistrationCertificate,

//     /// Скан/фото копии Доверенности о полномочиях представителя (если подписант - не ген директор)
//     @Default(IpDocPowerNote()) IpDocPowerNote  representativePowerNote,

//     /// выписка ЕГРИП
//     @Default(IpDocEgrip()) IpDocEgrip  egripCertificate,

//     /// СПравка об открытии расетного счет
//     @Default(IpDocBankStatement()) IpDocBankStatement  certificateOfBankAccount,
//   }) = IPDocuments;


export const buildPassport = (isRussianCitizen: boolean) => {



    const formProperties = buildProperties<Record<string,any>>(
        {
            number: {
                dataType: "string",
                name: "Номер",
                validation: { required: false },
            },
            serial: {
                dataType: "string",
                name: "Серия",
                validation: { required: false },
            },
            issuedAt: {
                dataType: "string",
                name: "Дата выдачи",
                validation: { required: false }
            }
        });
    if (isRussianCitizen == false) {

        formProperties['expiration'] = buildProperty<string>({
            dataType: "string",
            name: "Срок действия",
            // validation: { required: isRussianCitizen == false },
        });
    }

    formProperties['issuer'] = buildProperty({
        dataType: "string",
        name: "Кем выдан",
        // validation: { required: true },
    },);


    const docProperties = buildProperties<any>({
        passport: {
            dataType: "map",
            name: "Паспортные Данные",
            properties: formProperties,
            validation: { required: false }
        },
        attachment: buildAttachments()
    })

    return docProperties;
}

export const buildInn = (_legalForm: string, _userRole: string) => {
    const properties = buildProperties<any>(
        {
            number: {
                dataType: "string",
                name: "Номер",
                validation: { required: false },
            },
            issuedAt: {
                dataType: "string",
                name: "Дата выдачи",
                validation: { required: false }
            },
            issuedBy: {
                dataType: "string",
                name: "Кем выдан",
                validation: { required: false }
            }
        });
    
    const docProperties = buildProperties<any>({
        inn: {
            dataType: "map",
            name: "Данные ИНН",
            properties: properties,
        },
        attachment: buildAttachments()
    })

    return docProperties;
}


export const buildMigrationCard = () => {
    const properties = buildProperties<any>(
        {
            startDate: {
                dataType: "string",
                name: "Действует с",
                validation: { required: false },
            },
            endDate: {
                dataType: "string",
                name: "Действует до",
                validation: { required: false },
            },
            serial: {
                dataType: "string",
                name: "Серия",
                validation: { required: false },
            },
            idNumber: {
                dataType: "string",
                name: "Номер",
                validation: { required: false },
            },
        });


    const docProperties = buildProperties<any>({
        migrationCard: {
            dataType: "map",
            name: "Данные Миграционной карты",
            properties: properties,
        },
        attachment: buildAttachments()
    })

    return docProperties;
}


export const buildTempRegistration = () => {
  

    const docProperties = buildProperties<any>({

        attachment: buildAttachments()
    })

    return docProperties;
   
}



export const buildSnils = () => {
    const formProperties = buildProperties<any>(
        {
            idNumber: {
                dataType: "string",
                name: "СНИЛС",
                validation: { required: false },
            },
            residency: {
                dataType: "string",
                name: "Резидент",
                validation: { required: false },
            },
        });


    const docProperties = buildProperties<any>({
        snils: {
            dataType: "map",
            name: "Данные СНИЛС",
            properties: formProperties,
            validation: { required: false }
        },
        attachment: buildAttachments()
    })
    return docProperties;
}




export const buildTaxRegistration = () => {
    const properties = buildProperties<any>({});

    properties['attachment'] = buildAttachments();

    return properties;
}



export const buildPatent = () => {
    const formProperties = buildProperties<any>(
        {
            serie: {
                dataType: "string",
                name: "Серия",
                validation: { required: false },
            },
            idNumber: {
                dataType: "string",
                name: "Номер",
                validation: { required: false },
            },
            issuedBy: {
                dataType: "string",
                name: "Кем выдан",
                validation: { required: false },
            },
            startDate: {
                dataType: "string",
                name: "Действителен с",
                validation: { required: false },
            },
            endDate: {
                dataType: "string",
                name: "Действует по",
                validation: { required: false },
            },
            professionTitle: {
                dataType: "string",
                name: "Наименование профессии",
                validation: { required: false },
            },

        });


        const docProperties = buildProperties<any>({
            passport: {
                dataType: "map",
                name: "Данные СНИЛС",
                properties: formProperties,
            },
            attachment: buildAttachments()
        })
        return docProperties;
}





// ApplicantDocuments.foreignSelfEmployed({

// SelfEmployedApplicantDocTax taxRegistrationCertificate,
// ForeignSelfEmployedApplicantDocTax taxRegistrationCertificate,

// ForeignIndividualApplicantDocPatent patent,



export const documentsCollection = buildCollection<Documents>({
    name: "Пакеты документов",
    singularName: "Пакет документов",
    icon: "Description",
    path: "documents",
    permissions: (
        // { authController }
    ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],

    properties: {
        // id: {
        //     name: "",
        //     dataType: "string",
        //     validation: { required: true },
        //     readOnly: true,
        // },
        citizenship: {
            name: 'Гражданин',
            dataType: "string",
            enumValues: citizenshipBuilder(),
            defaultValue: "ru"
            // validation: { required: true }
        },
        userRole: {
            name: 'Роль',
            dataType: "string",
            enumValues: {
                "applicant": "Соискатель",
                "client": "Работодатель"
            },
            // readOnly: true,
        },
        legalForm: ({ values }) => {

            // const enumValues = buildEnumValues();
            const enumValues = values.userRole == "applicant" ? buildEnumValues({
                "individual": "Физлицо",
                "selfEmployed": "Самозанятый",
            }) : buildEnumValues({
                "ip": "ИП",
                "organization": "Организация",
            });

            return {
                dataType: 'string',
                name: 'Форма собственности',
                enumValues: enumValues,
                disabled: !values.userRole
            }
        },
        documents: ({ values }) => {

            const isRussianCitizen = values.citizenship == "ru"
            const docProperties = buildProperties<any>({});
            // passport 
            if (values.legalForm == 'individual' || values.legalForm == 'selfEmployed' || values.legalForm == 'ip') {
                const passportProperties = buildPassport(isRussianCitizen == true);

                docProperties['passport'] = buildProperty({
                    dataType: "map",
                    name: "Паспорт",
                    properties: passportProperties
                });
            }

            if (values.userRole == 'applicant' && ((values.legalForm == 'individual' && isRussianCitizen) || values.legalForm == 'selfEmployed')) {
                const innProperties = buildInn(values.legalForm, values.userRole!);

                docProperties['inn'] = buildProperty({
                    dataType: "map",
                    name: "ИНН",
                    properties: innProperties
                });
            }

            if ((isRussianCitizen == false) && values.userRole == 'applicant' && (values.legalForm == 'individual')) {
                const migrationCardProperties = buildMigrationCard();

                docProperties['migrationCard'] = buildProperty({
                    dataType: "map",
                    name: "Миграционная карта",
                    properties: migrationCardProperties
                });

                const tempRegistrationProperties = buildTempRegistration();

                docProperties['temporaryRegistration'] = buildProperty({
                    dataType: "map",
                    name: "Временная регистрация",
                    properties: tempRegistrationProperties
                });
            }


            if (values.userRole == 'applicant' && (values.legalForm == 'individual' || values.legalForm == 'selfEmployed')) {
                const snilsProperties = buildSnils();

                docProperties['snils'] = buildProperty({
                    dataType: "map",
                    name: "СНИЛС",
                    properties: snilsProperties
                });
            }


            if (values.userRole == 'applicant' && values.legalForm == 'selfEmployed') {
                const snilsProperties = buildTaxRegistration();

                docProperties['taxRegistration'] = buildProperty({
                    dataType: "map",
                    name: "Сертификат налоговой регистрации",
                    properties: snilsProperties
                });
            }

            if (values.userRole == 'applicant' && values.legalForm == 'individual' && isRussianCitizen == false) {
                const patentProperties = buildPatent();

                docProperties['patent'] = buildProperty({
                    dataType: "map",
                    name: "Патент",
                    properties: patentProperties
                });
            }


            if (values.userRole == 'client' && values.legalForm == "organization") {
                addOrganizationDocuments(docProperties);
            }


            if (values.userRole == 'client' && values.legalForm == "ip") {
                addIPDocuments(docProperties);
            }
            // return docProperties;

            return {
                name: "Документы",
                dataType: "map",
                properties: docProperties,
                validation: { required: false }
            }
        },

    },

    // Location details

});