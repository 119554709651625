import {
  buildCollection,
  DataSource,
  Entity,
  EntityReference,
  useDataSource,
  useFireCMSContext,
  useModeController,
  useSideEntityController,
  useStorageSource,
} from "firecms";

import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { MainContainer } from "@minchat/react-chat-ui";
import { Timestamp } from "firebase/firestore";
import { Applicant, applicantsCollection } from "../ui/collections/applicant";
import { ClientIp, clientsIpCollection } from "../ui/collections/clients-ip";
import {
  ClientOrganization,
  clientsOrgCollection,
} from "../ui/collections/clients-org";
import { ProfilePickerView } from "./UserSelectionModal";
import ConversationType from "@minchat/react-chat-ui/dist/ConversationType";
import { useWorker } from "@koale/useworker";
import { BehaviorSubject, Observable, switchMap } from "rxjs";

// import { useStorageUploadController } from 'firecms/dist/core/util/useStorageUploadController';

import { MessageContainer, MessageHeader } from "@minchat/react-chat-ui";
import MessageType from "@minchat/react-chat-ui/dist/MessageType";
import { Button, Divider, Grid, Typography, useThemeProps } from "@mui/material";
import { useTheme } from "@emotion/react";
import { light } from "@mui/material/styles/createPalette";
import { Client } from "../ui/collections/clients";
import { Channel } from "./domain/entity/channel";
import { ChatMessage } from "./domain/entity/message";
import { channelsCollection, messagesCollection } from "./domain/chat-collections";



type Profile = {
  imageUrl: string;
};

const listenMessages = (
  datasource: DataSource,
  channelId: string,
  onEvent: (entities: Entity<ChatMessage>[]) => void
) => {
  return datasource.listenCollection!<ChatMessage>({
    path: `channels/${channelId}/messages`,
    collection: messagesCollection,
    onUpdate: (entities: Entity<ChatMessage>[]) => {
      onEvent(entities);
    },
  });
};

export function createFirestoreObservable(
  dataSource: DataSource,
  channelId: string,
  onEvent: (entities: Entity<ChatMessage>[]) => void
) {
  return new Observable((subscriber) => {
    const unsubscribe = listenMessages(dataSource, channelId, onEvent);
    // Cleanup function
    return () => {
      unsubscribe(); // Unsubscribe when the observable is unsubscribed
    };
  });
}

export type SinglePersonChatProps = {
  userId: string;

  userEntity: Entity<ChatParticipant>;
};
export type ChatParticipant = Applicant | Client;

export function SinglePersonChat(props: SinglePersonChatProps) {
  const datasource = useDataSource();

  const assertApplicant = (
    prop: Entity<ChatParticipant>
  ): prop is Entity<Applicant> => true;
  const assertIp = (prop: Entity<ChatParticipant>): prop is Entity<ClientIp> =>
    true;
  const assertOrg = (
    prop: Entity<ChatParticipant>
  ): prop is Entity<ClientOrganization> => true;

  //   const isTypeB = (prop: MyPropType): prop is TypeB => prop.type === 'b';

  // begin with empty messages
  let [messages, setMessages] = useState<Entity<ChatMessage>[]>([]);

  // begin with empty messages
  let [messagesList, setMessagesList] = useState<MessageType[]>([]);

  // tracking for last update ( message time or channel metadata change)
  const [lastUpdatedAt, setLastUpdatedAt] = useState<number>(0);

  // tracking for channel being loaded
  let [selectedChannel, setSelectedChannel] = useState<
    Entity<Channel> | undefined
  >(undefined);

  const [loadedImages, setLoadedImages] = useState<
  Record<string, string | undefined>
>({});

const [lastConversion, setLastConversion] = useState<number>(0);

  const themeMode = useModeController();

  var startup = true;
  const storage = useStorageSource();
  const [selectedConversation, setSelectedConversation] =
  useState<any>(undefined);
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  // listen for channel change
  useEffect(() => {
    const channelChangeSubject = new BehaviorSubject(selectedChannel);

    const subscription = channelChangeSubject
      .pipe(
        switchMap((channel) =>
          createFirestoreObservable(datasource, channel!.id, (entities) => {
            // work wihth /messages colleciton event
            console.log("messages changed" + entities.length);
            entities.sort(
              (a, b) =>
                a.values.timestamp.valueOf() - b.values.timestamp.valueOf()
            );

            setMessages((m) => {
              const expanded = entities.flatMap((message) => {
                if (
                  message.values.attachments &&
                  message.values.attachments.length > 0
                ) {
                  const attachmentMessages = message.values.attachments
                    .filter((a) => a.type)
                    .map((attachment) => ({
                      ...message,
                      values: {
                        ...message.values,
                        attachments: [attachment],
                      },
                    }));

                  if (message.values.message) {
                    const textOnlyValues = { ...message.values };
                    textOnlyValues.attachments = [];

                    attachmentMessages.push({
                      ...message,
                      values: textOnlyValues,
                    });
                  }

                  return attachmentMessages;
                } else {
                  return [message];
                }
              });

              return expanded;
            });

            setLastUpdatedAt(Date.now());
          })
        )
      )
      .subscribe((entities) => {
        // Handle the snapshot updates for the specific data source and channel ID
      });

    return () => {
      subscription.unsubscribe(); // Unsubscribe when the component unmounts
    };
  }, [attachments, selectedChannel, lastUpdatedAt, lastConversion]);



  // Load current user's channel at startup.
  // todo: create channel if not exists
  useEffect(() => {
    datasource.fetchEntity!<Channel>({
      path: "channels",
      collection: channelsCollection,
      entityId: props.userId,
    })
      .then(async (channel) => {
        console.log("channel does not exist");

        let freshChannel =
          channel ||
          (await datasource.saveEntity<Channel>({
            path: `channels`,
            entityId: props.userId,
            collection: channelsCollection,
            values: {
              displayName: mapUserIdToDisplayName(props.userEntity),
              profileRef: new EntityReference(
                props.userEntity.id,
                props.userEntity.path
              ),
              legalForm: props.userEntity.values.legalForm,
              userRole: props.userEntity.values.userRole,
              imageUrl: props.userEntity.values.imageUrl,
            },
            status: "new",
          }));

        setSelectedChannel(channel || freshChannel);
        setLastUpdatedAt(Date.now());
      })
      .catch((error) => {
        console.log(error);
      });
    // todo catch error
  }, [startup]);

  const mapUserForm = (legalForm?: string, _?: string): string => {
    let display = "";
    switch (legalForm) {
      case "ip":
        display += "ИП";
        break;
      case "organization":
        display += " Орг.";
        break;
      case "individual":
        display += "ФизЛицо";
        break;
      case "selfEmployed":
        display += "Самозанятый";
        break;
    }
    return display;
  };

  const mapUserIdToDisplayName = (userEntity: Entity<ChatParticipant>) => {
    return assertApplicant(userEntity)
      ? `${userEntity.values.familyName} ${userEntity.values.firstName}`
      : assertIp(userEntity)
      ? `${userEntity.values.titleFull}`
      : assertOrg(userEntity)
      ? `${userEntity.values.titleShort}`
      : "";
  };

  // map name
  const mapChannelToDisplayName = (channel?: Entity<Channel>) => {
    if (channel?.values.displayName) {
      return channel.values.displayName;
    }

    const userChannelId = channel?.id;
    if (!userChannelId) return "Загрузка";

    return mapUserIdToDisplayName(props.userEntity);
  };

  useEffect(() => {
    if (selectedChannel == undefined) return;

    console.log(`triggered selected channel ${JSON.stringify(messages)}`);

    const chatMessages: MessageType[] =
      selectedChannel == undefined
        ? [{ user: { id: "", name: "Загрузка" }, text: "a" }]
        : (messages || []).map((message) => {
          let imageUrl;
          let fileLink;

          const attachment = message.values.attachments?.[0];
          const path = attachment && attachment.path;

          const url = loadedImages[path];
          let linkComponent;
          if (url) {
            if (url.startsWith("file://")) {
              fileLink = url.split("file://")[1];
              linkComponent = (
                <Grid container direction={"column"}>
                  <Grid item>
                    <Typography variant="body2">
                      {" "}
                      Прикрепленный файл:
                    </Typography>
                  </Grid>

                  <Grid alignSelf={"end"} item>
                    <Typography variant="label" color="secondary">
                      {" "}
                      {attachment.name}{" "}
                    </Typography>
                  </Grid>

                  <Grid paddingTop={2} alignSelf={"end"}>
                    <a href={fileLink}>
                      <Button color="secondary" variant="outlined">
                        Скачать
                      </Button>
                    </a>
                  </Grid>
                </Grid>
              );
            } else {
              imageUrl = url;
            }
          }

          const chatBubble: MessageType = {
            user: {
              id: message.values.support
                ? "support"
                : selectedChannel?.id || "id",
              name: mapChannelToDisplayName(selectedChannel),
              // avatar: profiles.get(selectedChannel!.id)?.imageUrl,
            },
            text: ((fileLink && linkComponent) ||
              message.values.message) as any,
            created_at: message.values.timestamp.toUTCString(),
            image: imageUrl ? imageUrl : undefined,
          };

          return chatBubble;
          });

    setMessagesList(chatMessages);
  }, [selectedChannel, messages, lastUpdatedAt]);

 


  // ====== FIlE UPLOAD SECTION ========


  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      console.log("clac");
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: FileList | null = event.target.files;
    if (selectedFiles) {
      // Now you can handle the selected files as needed
      console.log("Selected files:", selectedFiles);
      const files = Array.from(selectedFiles);
      console.log("files array:", files);
      setAttachments(files);
    }
  };


  const getImageUrlFromPath = useCallback(
    async (imagePath: string) => {
      if (loadedImages[imagePath]) {
        console.log("has image url, ");
        return;
      } else {
        setLoadedImages((li) => {
          li[imagePath] = undefined;
          return li;
        });

        const result = await storage.getDownloadURL(imagePath);

        console.log(`result ${result}`, result);

        setLoadedImages((li) => {
          li[imagePath] = result.url;
          return li;
        });
        setLastConversion(Date.now());
      }
    },
    [messages]
  );

  const getFileUrlFromPath = async (filePath: string) => {
    if (loadedImages[filePath]) {
      console.log("has image url, ");
      return;
    } else {
      setLoadedImages((li) => {
        li[filePath] = undefined;
        return li;
      });

      const result = await storage.getDownloadURL(filePath);

      console.log(`result ${result}`, result);

      setLoadedImages((li) => {
        li[filePath] = "file://" + result.url;
        return li;
      });
      setLastConversion(Date.now());
    }
  };

  useEffect(() => {
    if (selectedChannel == undefined) return;

    for (const message of (messages || []).values()) {
      const messageAttachments = message.values.attachments;

      if (
        (messageAttachments || []).length > 0 &&
        !loadedImages[messageAttachments[0].path]
      ) {
        const attachment = messageAttachments[0];
        console.log("attacha", attachment);
        if (attachment && attachment.type && attachment["type"] == "image") {
          getImageUrlFromPath(message.values.attachments[0].path);
        } else {
          getFileUrlFromPath(message.values.attachments[0].path);
        }
      }
    }
  }, [lastUpdatedAt, lastConversion, selectedChannel]);

  const allowedAttachmentTypes = {
    document: ["pdf", "doc", "docx"],
    image: ["jpg", "png", "jpeg", "gif", "svg", "webp", "tiff", "bmp", "heic"],
  };

  const uploadAttachments = async (attachments: File[], userId: string) => {
    const uploadedAttachments = [];

    for (const file of attachments) {
      const extension = file.name.split(".").pop()!.toLowerCase();
      const attachmentType = Object.entries(allowedAttachmentTypes).find(
        ([key, value]) => value.includes(extension)
      ) || ["document", ["*"]];

      const uploadTask = await storage.uploadFile({
        file,
        path: `attachments/${userId}`,
        metadata: {
          contentType: file.type,
        },
      });

      console.log("uploaded task:", uploadTask);

      uploadedAttachments.push({
        name: file.name,
        path: uploadTask.path,
        type: attachmentType[0],
      });
    }

    return uploadedAttachments;
  };

  const send = useCallback(
    async (text: any) => {
      let message: any = {
        message: text,
        timestamp: new Date(),
        support: true,
      };

      let uploaded;
      if (attachments.length > 0) {
        console.log("upload attachments? ", attachments);
        uploaded = await uploadAttachments(attachments, selectedChannel!.id);
        message.attachments = uploaded;
      }

      try {
        await datasource.saveEntity({
          path: `channels/${selectedChannel!.id}/messages`,
          entityId: datasource.generateEntityId(
            `channels/${selectedChannel!.id}/messages`
          ),
          collection: messagesCollection,
          values: message,
          status: "new",
        });
        setAttachments([]);
      } catch (e) {
        console.error(e);
      }
    },
    [attachments.length, selectedChannel?.id]
  );

  return (
    <div style={{ height: "100%" }}>
      <MessageContainer
        themeColor={themeMode.mode == "light" ? "#EA572B " : "#EA572B"}
        currentUserId="support"
        messages={messagesList}
        onSendMessage={send}
        loading={!selectedChannel}
        onAttachClick={handleFileButtonClick}
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        accept=".jpg,.jpeg,.png,.pdf,.heic,.docx,.doc,.xls,.csv"
        onChange={handleFileSelect}
      />
    </div>
  );
}