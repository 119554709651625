import { Timestamp } from "firebase/firestore"
import { buildCollection, buildEntityCallbacks, buildProperties, buildProperty } from "firecms"
import { baseApiUrl } from "../../App"
import { ClientProfileStatus } from "../../domain/enums/enums"
import { clientFeedbacksCollections } from "./feedbacks"
import { currentBillsCollection } from "./employer-bills"
import { addIPDocuments, buildPassport } from "./documents"


export type ClientIp = {

    readonly legalForm: string
    readonly userRole: string
    userId: string
    status: ClientProfileStatus
    contactPerson: string
    phoneNumber: string
    email: string
    inn: string
    titleFull: string
    taxScheme: string
    registrationDate?: Date | Timestamp | string
    registrationAddress?: string
    ogrn?: string
    ogrnDate?: Date | Timestamp | string
    bic?: string
    bankName?: string

    currentAccountNumber?: string
    corrAccount?: string
    imageUrl?: string


    documents: Record<string, any>
    /// ----
    // надежный / ннеадежный клиент
    trusted?: boolean,
    creditLimit?: number,
    postpaid?: boolean,
    // комиссия пользования сервисом*
    comission?: number,
    rating: number,
    reviewsCount: number,
}


const ipCallbacks = buildEntityCallbacks<ClientIp>({

    onPreSave: async ({
        // collection,
        // context,
        // path,
        // entityId,
        values,
        status
    }) => {

        // return the updated values
        if (status == "new") {
            console.log("status is new")
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber: values.phoneNumber })
            };
            let data = await fetch(`${baseApiUrl}/user`, requestOptions)
                .then(response => response.json())

            console.log("createduser")
            console.log(data)

            values.userId = data.uid


            // .then(data => this.setState({ postId: data.id }));
            return values;
        } else {
            return values;
        }

    },
    async onSaveSuccess({ status, values, entityId }) {
        if (status == "new") {
            console.log("Linking user with profile")
            const requestOptions = {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    userId: values.userId,
                    profileId: entityId,
                    userRole: "client",
                    legalForm: 'ip',
                })
            };
            let data = await fetch(`${baseApiUrl}/user/linkProfile`,
                requestOptions,
            )

            console.log("linked user")
            console.log(data)
        }
    },
    // onIdUpdate(idUpdateProps) {
    //     console.log("onIdUpdate");
    //     console.log(idUpdateProps.entityId)
    //     return idUpdateProps.entityId || '';
    // },

});

export const ipPropertiesBuilder = buildProperties<ClientIp>({
    legalForm: {
        name: "Форма собственности",
        dataType: "string",
        validation: { required: true },
        readOnly: true,
        defaultValue: "ip",
        enumValues: {
            'ip': "ИП"
        }
    },
    userRole: {
        name: "Роль",
        dataType: "string",
        validation: { required: true },
        readOnly: true,
        defaultValue: "client",
        enumValues: {
            "client": "Работодатель"
        },
        hideFromCollection: true,
    },
    status: {
        name: "Статус профиля",
        dataType: "string",
        validation: { required: true },
        enumValues: {
            moderation: "На модерации",
            active: "Активен",
            blocked: "Заблокирован",
            deleted: "Удален"
        }
    },
    phoneNumber: {
        name: "Номер телефона",
        validation: { required: false },
        dataType: "string",
    },
    contactPerson: {
        name: "Контактное лицо",
        dataType: "string",
        validation: { required: true }
    },


    email: {
        name: "EMail",
        dataType: "string",
        validation: {
            required: true,
        },
        email: true,
    },

    // @IsNumberString({no_symbols: true})
    // @Length(12,12)
    inn: {
        name: "ИНН",
        dataType: "string",
        validation: { required: true, min: 12, max: 12 },

    },


    // @IsString()
    titleFull: {
        name: "Полное наименование",
        dataType: "string",
        validation: { required: true }
    },

    // @IsEnum(TaxScheme) 
    // @IsOptional()
    taxScheme: {
        name: "Форма налогообложения",
        dataType: "string",
        validation: { required: true },
        enumValues: { USN: "УСН", OSNO: "ОСНО" }
    },
    // @IsOptional()
    // @IsDate()
    registrationDate: {
        name: "Дата регистрации",
        dataType: "date",
        validation: { required: false },
        mode: "date",
    },
    // @IsOptional()
    // @IsString()
    registrationAddress: {
        name: "Адрес регистрации",
        validation: { required: false },
        dataType: "string",
    },
    // @IsOptional()
    // @IsNumberString({no_symbols: true})
    // @Length(13,13)
    ogrn: {
        name: "ОГРН",
        dataType: "string",
        validation: { required: false, min: 13, max: 13 },
    },
    // @IsOptional()
    // @IsDateString()
    ogrnDate: {
        name: "Дата ОГРН",
        dataType: "date",
        validation: { required: false },
        mode: "date_time",
    },
    // @IsOptional()
    // @IsNumberString({no_symbols: true})
    // @Length(9,9)
    bic: {
        name: "БИК",
        dataType: "string",
        validation: { required: false, min: 9, max: 9 },
    },
    // @IsOptional()
    // @IsString()
    bankName: {
        name: "Наименование банка",
        dataType: "string",
        validation: { required: false, },
    },
    // @IsOptional()
    // @IsNumberString({no_symbols: true})
    // @Length(20,20)
    currentAccountNumber: {
        name: "Расчетный счет",
        dataType: "string",
        validation: { required: false, min: 20, max: 20 },
    },
    // @IsOptional()
    // @IsNumberString({no_symbols: true})
    // @Length(20,20)
    corrAccount: {
        name: "Корр. счет",
        dataType: "string",
        validation: { required: false, min: 20, max: 20 },
    },
    // @IsOptional()
    // @IsUrl()
    imageUrl: ({ entityId }) => ({
        name: "Изображение профиля",
        validation: {
            required: false,
        },
        dataType: "string",
        storage: {
            fileName: `avatar_client_${entityId}_${Timestamp.now().seconds}.{file.ext}`,
            storagePath: `profile/${entityId}/avatar/`,
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000"
            }
        }
    }),
    userId: {
        name: 'userId',
        dataType: "string",
        hideFromCollection: true,
        disabled: true,
        // editable: false,
        readOnly: true,
    },
    documents: ({ values }) => {

        const docProperties = buildProperties<any>({});
        // passport


        const passportProperties = buildPassport(true);
        docProperties['passport'] = buildProperty({
            dataType: "map",
            name: "Паспорт",
            properties: passportProperties
        });

        addIPDocuments(docProperties);

        return {
            name: "Документы",
            dataType: "map",
            properties: docProperties,
            validation: { required: false }
        }
    },
    // надежный / ннеадежный клиент
    trusted: {
        name: 'Надежный клиент',
        dataType: 'boolean',
        defaultValue: false,
    },
    creditLimit: ({ values }) => ({
        name: "Кредитный лимит",
        dataType: "number",
        disabled: !values.trusted,
        validation: { required: values.trusted, positive: true },
    }),
    postpaid: ({ values }) => ({
        name: "Отсрочка (постоплата)",
        dataType: "boolean",
        defaultValue: false,
        validation: { required: values.trusted },
        disabled: !values.trusted,
    }),
    // комиссия пользования сервисом*
    comission: () => ({
        name: "Комиссия (коэффициент)",
        dataType: "number",
        validation: { required: true, positive: true },
        defaultValue: 2.21,
    }),
    rating: {
        name: "Рейтинг",
        dataType: "number",
        defaultValue: 0,
        validation: {
            min: 0,
            max: 5,
        },
    },
    reviewsCount: {
        name: "Отзывы",
        dataType: "number",
        defaultValue: 0,
        validation: {
            min: 0,
            integer: true,
        },
    },
},);


export const clientsIpCollection = buildCollection<ClientIp>({
    name: "Работодатели (ИП)",
    singularName: "Работодатель (ИП)",
    icon: "BusinessCenter",
    hideFromNavigation: true,
    path: "profiles",
    callbacks: ipCallbacks,
    group: "Работодатели",
    alias: 'clients-ip',
    subcollections: [currentBillsCollection, clientFeedbacksCollections(false)],
    forceFilter: {
        legalForm: ["==", "ip"]
    },
    permissions: (
        // { authController }
    ) => ({
        read: true,
        edit: true,
        create: true,
        delete: true
    }),
    // subcollections: [
    //     localeCollection
    // ],
    properties: ipPropertiesBuilder,
}
);