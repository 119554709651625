export enum ClientProfileStatus {
    moderation = "moderation",
    active = "active",
    blocked = "blocked",
    deleted = "deleted"
}


export enum VacancyStatus {
    awaitingResponse = "awaitingResponse",
    newResponses = "newResponses",
    waitingStartWork = "waitingStartWork",
    atWork = "atWork",
    moderation = "moderation",
    failedModeration = "failedModeration",
    tabelOnModeration = "tabelOnModeration",
    tabelFailedModeration = "tabelFailedModeration",
    paymentPending = "paymentPending",
    archived = "archived",
    reminder = "reminder",
    paid = "paid",
    inArchiveWaitingPayment = "inArchiveWaitingPayment"
}



export enum Employment {
    permanent = "permanent",
    temporary = "temporary"
}


export enum KnowledgeRussian {
    understand = "understand",
    understandTalk = "understandTalk",
    understandTalkWrite = "understandTalkWrite"
}


