import { Grid } from "@mui/material";
import { Entity } from "firecms";
import React, { useEffect, useState } from "react";

interface GroupedObject<T> {
  groupingObject: any;
  groupId: string;
  items: ItemWithNode<T>[];
}

export type ItemWithNode<T> = {
  item: T;
  node: React.ReactNode;
};

interface Props<T> {
  items: ItemWithNode<T>[];
  groupingSelector: (item: ItemWithNode<T>) => any;
  groupingIdSelector: (groupingObject: any) => string;
  groupTitleRenderer: (
    groupTitle: any,
    group: GroupedObject<T>
  ) => React.ReactNode;
  itemRenderer: (item: ItemWithNode<T>, index: number) => React.ReactNode;
}



const GroupedItems:React.FC<Props<any>> = <T extends Entity<any>>({
  items,
  groupingSelector,
  groupTitleRenderer,
  itemRenderer,
  groupingIdSelector,
}: Props<T>) => {
  


  const [groups, setGroups] = useState<GroupedObject<T>[]>([]);

  const [groupedData, setGroupedData] = useState<any>(new Map());
  
  useEffect(() => {
    const groupedData = new Map<any, { group: any; item: ItemWithNode<T> }[]>();

    setGroupedData(groupedData);

    items.forEach((item) => {
      const group = groupingSelector(item);
  
      const groupId = groupingIdSelector(group);
      if (!groupedData.has(groupId)) {
        groupedData.set(groupId, []);
      }
      groupedData.get(groupId)!.push({ group, item });
    });

    const groups: GroupedObject<T>[] = Array.from(groupedData.entries()).reduce<
      GroupedObject<T>[]
    >((result, [groupTitle, groupedItems]) => {
      result.push({
        groupingObject: groupedItems[0].group,
        groupId: groupTitle,
        items: groupedItems.map((e) => e.item),
      });
      return result;
    }, []);

    setGroups

  }, [items]);

  // console;

  //   const groups: GroupedObject<T>[] = Array.from(groupedData.entries()).map(
  //     ([groupTitle, items]) => ({
  //       groupTitle,
  //       items,
  //     })
  //   );


 

  console.log("groups", groups);
  return (
    // Column List of groups
    <Grid container direction={"column"} rowGap={1} columnGap={1}>
      {groups.map((group, index) => (
        <Grid key={JSON.stringify(group.groupingObject) + `${index}`} container direction={"row"}>
          <Grid item width={220}>
            {groupTitleRenderer(group.groupingObject, group)}
            </Grid>
            <Grid item>
                <Grid container direction={'row'} columnGap={1}>
              {group.items.map((item, index) => (
                <Grid key={item.toString() + `${index}`}>
                  {itemRenderer(item, index)}
                </Grid>
              ))}
              </Grid>
            </Grid>
          </Grid>

      ))}
    </Grid>
  );
};

export default GroupedItems;
