import React, { useState } from "react";
import { Grid, Button, Typography, Box } from "@mui/material";
import { Entity, EntityReference, ReferencePreview } from "firecms";
import { Chip, Tooltip } from "@mui/material";
import { ReactJSXElement } from "@emotion/react/types/jsx-namespace";
import { green, lightBlue } from "@mui/material/colors";

export type ClientFilterProps = {
  selectedItems: Entity<any>[];
  onDelete: (clientId: string) => void;
  labelRenderer: (item: Entity<any>) => ReactJSXElement;
  nodesRenderer: (
    items: { item: any; node: React.ReactNode }[]
  ) => React.ReactNode;
  noneText: string;
};
const FilterWithChips: React.FC<ClientFilterProps> = ({
  selectedItems,
  onDelete,
  labelRenderer,
  nodesRenderer,
  noneText,
}) => {
  const [tooltipIdValue, setTooltipIdValue] = useState<string | undefined>();

  return (
    <Grid
      color={green}
      columnGap={2}
      rowSpacing={1}
      container
      direction="row"
      justifyContent={"start"}
      justifyItems={"start"}
      alignContent={"start"}
      alignItems={"start"}
    >
      {selectedItems.length <= 0 ? (
        <Grid item>
          <Typography variant="body2" px={2}>
            {noneText || "Все записи"}
          </Typography>
        </Grid>
      ) : (
        nodesRenderer(
          selectedItems.map((item) => ({
            node: (
              <Grid item key={item.id} p={0}>
                <Tooltip
                  open={item.id == tooltipIdValue}
                  disableFocusListener
                  disableHoverListener
                  // onClickCapture={() => setTooltipIdValue(tooltipIdValue == item.id ? undefined: item.id)}
                  disableTouchListener
                  PopperProps={{
                    disablePortal: true,
                    onMouseLeave: () => setTooltipIdValue(undefined),
                    sx: { margin: "0", padding: "0" },
                  }}
                  // PopperComponent={()=>( <Box bgcolor={lightBlue[50]}>
                  // <ReferencePreview
                  //   reference={new EntityReference(item.id, item.path)}
                  //   size="regular"
                  // />
                  // </Box>)}

                  sx={{ margin: "0", padding: "0" }}
                  title={
                    <Box p={0} bgcolor={lightBlue[50]}>
                      <ReferencePreview
                        reference={new EntityReference(item.id, item.path)}
                        size="regular"
                      />
                    </Box>
                  }
                >
                  <Chip
                    size="small"
                    onDelete={() => onDelete(item.id)} // Call handleDelete with the client data
                    label={labelRenderer(item)}
                    variant="outlined"
                    onClick={() =>
                      setTooltipIdValue(
                        tooltipIdValue == item.id ? undefined : item.id
                      )
                    }
                  />
                </Tooltip>
              </Grid>
            ),
            item,
          }))
        )
      )}
    </Grid>
  );
};

export default FilterWithChips;
