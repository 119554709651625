import { Timestamp } from "firebase/firestore";
import {
  buildCollection,
  buildEntityCallbacks,
  buildProperties,
  buildProperty,
  EntityReference,
  PropertyBuilderProps,
} from "firecms";
import { baseApiUrl } from "../../App";
import { citizenshipBuilder } from "../builders/enum_builders";
import { Specialty } from "./specialties";
import {
  buildInn,
  buildMigrationCard,
  buildPassport,
  buildPatent,
  buildSnils,
  buildTaxRegistration,
  buildTempRegistration,
} from "./documents";
import { Typography } from "@mui/material";
import { SinglePersonChat } from "../../chat/SinglePersonChat";
import { applicantFeedbacksCollections } from "./feedbacks";
import { creditCardsCollection } from "./cards";

export type Applicant = {
  familyName: string;
  legalForm: string;
  userRole: string;
  firstName: string;
  middleName: string;
  email?: string;
  citizenship: string;
  phoneNumber: string;
  birthDate?: Date | Timestamp | string;
  imageUrl: string;
  registrationAddress?: string;
  userId: string;
  specialties: Specialty[];
  documents: Record<string, any>;
  documentsChecked: boolean;
};

const applicantCallbacks = buildEntityCallbacks<Applicant>({
  onPreSave: async ({
    // collection,
    // context,
    // path,
    // entityId,
    values,
    status,
  }) => {
    // return the updated values
    if (status == "new") {
      console.log("status is new");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ phoneNumber: values.phoneNumber }),
      };
      let data = await fetch(`${baseApiUrl}/user`, requestOptions).then(
        (response) => response.json()
      );

      console.log("createduser");
      console.log(data);

      values.userId = data.uid;

      // .then(data => this.setState({ postId: data.id }));
      return values;
    } else {
      return values;
    }
  },
  async onSaveSuccess({ status, values, entityId }) {
    if (status == "new") {
      console.log("Linking user with profile");
      const requestOptions = {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: values.userId,
          profileId: entityId,
          userRole: "applicant",
          legalForm: "individual",
        }),
      };
      let data = await fetch(`${baseApiUrl}/user/linkProfile`, requestOptions);

      console.log("createduser");
      console.log(data);

      // .then(data => this.setState({ postId: data.id }));
    }
  },
  onIdUpdate(idUpdateProps) {
    console.log("onIdUpdate");
    console.log(idUpdateProps.entityId);

    return idUpdateProps.entityId || "";
  },
});

export const applicantsCollection = buildCollection<Applicant>({
  name: "Соискатели",
  singularName: "Соискатель",
  icon: "Boy",
  path: "user_profiles/applicants/profiles",
  callbacks: applicantCallbacks,
  group: "Профили пользователей",
  subcollections: [applicantFeedbacksCollections(false),creditCardsCollection],
  permissions: () =>
    // { authController }
    ({
      read: true,
      edit: true,
      create: true,
      delete: true,
    }),
  // subcollections: [
  //     localeCollection
  // ],

  views: [
    {
      name: "Чат",
      path: "chat",
      Builder: ({ entity }) => {
        return <SinglePersonChat userEntity={entity!} userId={entity!.id} />;
      },
    },
  ],
  properties: {
    legalForm: {
      name: "Форма собственности",
      dataType: "string",
      validation: { required: true },
      readOnly: true,
      enumValues: {
        individual: "Физ.лицо",
        selfEmployed: "Самозанятный",
      },
    },
    userRole: {
      name: "Роль",
      dataType: "string",
      validation: { required: true },
      readOnly: true,
      defaultValue: "applicant",
      enumValues: {
        applicant: "Соискатель",
      },
      hideFromCollection: true,
    },
    familyName: {
      name: "Фамилия",
      validation: { required: true },
      dataType: "string",
    },
    firstName: {
      name: "Имя",
      validation: { required: true },
      dataType: "string",
    },
    middleName: {
      name: "Отчество",
      validation: {
        required: false,
      },
      dataType: "string",
    },
    email: {
      name: "EMail",
      validation: { required: false },
      dataType: "string",
      email: true,
    },
    birthDate: {
      name: "Дата рождения",
      validation: {
        required: false,
      },
      dataType: "date",
      mode: "date",
    },
    citizenship: {
      name: "Гражданство",
      dataType: "string",
      enumValues: citizenshipBuilder(),
    },
    imageUrl: ({ entityId }) => ({
      name: "Изображение профиля",
      validation: {
        required: false,
      },
      dataType: "string",
      storage: {
        fileName: `avatar_applicant_${entityId}_${
          Timestamp.now().seconds
        }.{file.ext}`,
        storagePath: `profile/${entityId}/avatar/`,
        acceptedFiles: ["image/*"],
        metadata: {
          cacheControl: "max-age=1000000",
        },
      },
    }),
    phoneNumber: {
      name: "Номер телефона",
      validation: { required: false },
      dataType: "string",
    },
    registrationAddress: {
      name: "Адрес регистрации",
      validation: { required: false },
      dataType: "string",
    },
    specialties: {
      name: "Специальности",
      dataType: "array",
      of: {
        dataType: "map",
        name: "Специальность",
        properties: {
          category: {
            name: "Категория",
            dataType: "string",
          },
          name: {
            name: "Название",
            dataType: "string",
          },
        },
        // previewAsTag: true
      },
      validation: { required: true },
    },
    userId: {
      name: "userId",
      dataType: "string",
      hideFromCollection: true,
      disabled: true,
      // editable: false,
      readOnly: true,
    },
    documentsChecked: {
      name: "Документы проверены",
      dataType: "boolean",
    },
    documents: ({ values }: PropertyBuilderProps<Applicant>) => {
      const docProperties = buildProperties<Record<string, any>>({});
      const isRussianCitizen = values.citizenship === "ru";
      const isEvrazisMember = ["by", "kz", "am", "ky", "kg", "ru"].includes(
        values.citizenship || "ru"
      );

      // passport
      if (
        values.legalForm == "individual" ||
        values.legalForm == "selfEmployed"
      ) {
        const passportProperties = buildPassport(isRussianCitizen == true);

        docProperties["passport"] = buildProperty({
          dataType: "map",
          name: "Паспорт",
          validation: { required: false },
          properties: passportProperties,
        });
      }

      if (
        (values.legalForm == "individual" && isRussianCitizen) ||
        values.legalForm == "selfEmployed"
      ) {
        const innProperties = buildInn(values.legalForm, values.userRole!);

        docProperties["inn"] = buildProperty({
          dataType: "map",
          name: "ИНН",
          validation: { required: false },
          properties: innProperties,
        });
      }

      if (!isRussianCitizen && values.legalForm == "individual") {
        const migrationCardProperties = buildMigrationCard();

        docProperties["migrationCard"] = buildProperty({
          dataType: "map",
          name: "Миграционная карта",
          validation: { required: false },
          properties: migrationCardProperties,
        });

        const tempRegistrationProperties = buildTempRegistration();

        docProperties["temporaryRegistration"] = buildProperty({
          dataType: "map",
          name: "Временная регистрация",
          validation: { required: false },
          properties: tempRegistrationProperties,
        });
      }

      if (
        values.legalForm == "individual" ||
        values.legalForm == "selfEmployed"
      ) {
        const snilsProperties = buildSnils();

        docProperties["snils"] = buildProperty({
          dataType: "map",
          name: "СНИЛС",
          validation: { required: false },
          properties: snilsProperties,
        });
      }

      if (
        values.userRole == "applicant" &&
        values.legalForm == "selfEmployed"
      ) {
        const taxRegProperties = buildTaxRegistration();

        docProperties["taxRegistrationCertificate"] = buildProperty({
          dataType: "map",
          name: "Сертификат налоговой регистрации",
          validation: { required: false },
          properties: taxRegProperties,
        });
      }

      if (values.legalForm == "individual" && !isEvrazisMember) {
        const patentProperties = buildPatent();

        docProperties["patent"] = buildProperty({
          dataType: "map",
          name: "Патент",
          validation: { required: false },
          properties: patentProperties,
        });
      }

      return buildProperty({
        name: "Документы",
        dataType: "map",
        validation: { required: false },
        properties: docProperties,
      });
    },
  },
});
