import {
  buildCollection,
  DataSource,
  Entity,
  EntityReference,
  useDataSource,
  useModeController,
  useSideEntityController,
  useStorageSource,
} from "firecms";

import { useCallback, useEffect, useRef, useState } from "react";
import { MainContainer, Message } from "@minchat/react-chat-ui";
import { serverTimestamp, Timestamp } from "firebase/firestore";
import { applicantsCollection } from "../ui/collections/applicant";
import { clientsIpCollection } from "../ui/collections/clients-ip";
import { clientsOrgCollection } from "../ui/collections/clients-org";
import { ProfilePickerView } from "./UserSelectionModal";
import ConversationType from "@minchat/react-chat-ui/dist/ConversationType";
import { useWorker } from "@koale/useworker";
import { BehaviorSubject, Observable, switchMap } from "rxjs";
import { FileMessage, PhotoMessage } from "react-chat-elements";
import { Button, colors, Grid, Typography } from "@mui/material";
import MessageType from "@minchat/react-chat-ui/dist/MessageType";
import { Channel } from "./domain/entity/channel";
import { ChatMessage } from "./domain/entity/message";
import { profilesCollection } from "./domain/chat-collections";
import { messagesCollection } from "./domain/chat-collections";
import { channelsCollection } from "./domain/chat-collections";

const listenMessages = (
  datasource: DataSource,
  channelId: string,
  onEvent: (entities: Entity<ChatMessage>[]) => void
) => {
  return datasource.listenCollection!<ChatMessage>({
    path: `channels/${channelId}/messages`,
    collection: messagesCollection,
    onUpdate: (entities: Entity<ChatMessage>[]) => {
      onEvent(entities);
    },
  });
};

export function createFirestoreObservable(
  dataSource: DataSource,
  channelId: string,
  onEvent: (entities: Entity<ChatMessage>[]) => void
) {
  return new Observable((subscriber) => {
    const unsubscribe = listenMessages(dataSource, channelId, onEvent);
    // Cleanup function
    return () => {
      unsubscribe(); // Unsubscribe when the observable is unsubscribed
    };
  });
}

export function Channels() {
  var startup = true;

  let [channels, setChannels] = useState<Entity<Channel>[]>([]);

  let [profiles, setProfiles] = useState<Map<string, any>>(
    new Map<string, any>()
  );

  let [messages, setMessages] = useState<Map<string, Entity<ChatMessage>[]>>(
    new Map<string, Entity<ChatMessage>[]>()
  );

  let [selectedChannel, setSelectedChannel] = useState<
    Entity<Channel> | undefined
  >(undefined);

  const [selectedConversation, setSelectedConversation] =
    useState<any>(undefined);

  const [lastUpdatedAt, setLastUpdatedAt] = useState<number>(0);

  const storage = useStorageSource();
  const datasource = useDataSource();
  const themeMode = useModeController();
  const sideEntityController = useSideEntityController();

  // 1) Load channels

  // 2) Load messages

  // 3)

  // listen to new messages
  useEffect(() => {
    const channelChangeSubject = new BehaviorSubject(selectedChannel);

    const subscription = channelChangeSubject
      .pipe(
        switchMap((channel) =>
          createFirestoreObservable(datasource, channel!.id, (entities) => {
            // work wihth /messages colleciton event
            console.log("messages changed" + entities.length);
            entities.sort(
              (a, b) =>
                a.values.timestamp.valueOf() - b.values.timestamp.valueOf()
            );

            setMessages((m) => {
              const expanded = entities.flatMap((message) => {
                if (
                  message.values.attachments &&
                  message.values.attachments.length > 0
                ) {
                  const attachmentMessages = message.values.attachments
                    .filter((a) => a.type)
                    .map((attachment) => ({
                      ...message,
                      values: {
                        ...message.values,
                        attachments: [attachment],
                      },
                    }));

                  if (message.values.message) {
                    const textOnlyValues = { ...message.values };
                    textOnlyValues.attachments = [];

                    attachmentMessages.push({
                      ...message,
                      values: textOnlyValues,
                    });
                  }

                  return attachmentMessages;
                } else {
                  return [message];
                }
              });

              m.set(channel!.id, expanded);
              const map = new Map(m);
              console.log(map);
              return m;
            });

            setLastUpdatedAt(Date.now());
          })
        )
      )
      .subscribe((entities) => {
        // Handle the snapshot updates for the specific data source and channel ID
      });

    return () => {
      subscription.unsubscribe(); // Unsubscribe when the component unmounts
    };
  }, [datasource, selectedChannel?.id]);

  const fetchProfile = useCallback(() => {
    channels.forEach((channel) => {
      if (!profiles?.get(channel.id)) {
        datasource.fetchEntity!({
          path: channel.values.profileRef.path,
          entityId: channel.values.profileRef.id,
          collection: profilesCollection,
        }).then((profile) => {
          setProfiles((p) => {
            p.set(channel.id, { ...profile?.values });
            return p;
          });
        });
      }
    });
  }, []);

  // startup effect
  useEffect(() => {
    // start listening channels colelciton on startup
    datasource.listenCollection!<Channel>({
      path: "channels",
      collection: channelsCollection,
      // whenever some doc under channels is updated
      onUpdate: async (entities: Entity<Channel>[]) => {
        // iterate over channels
        entities.map((channel) => {
          // check if Component has a profile loaded alreadt
          if (!profiles?.get(channel.id)) {
            console.log("no profile, fetching");
            // if not - begin listening the profile
            fetchProfile();
          }
        });

        setChannels((_) => {
          return entities;
        });
      },
    });
  }, [startup]);

  const mapUserForm = (legalForm?: string, _?: string): string => {
    let display = "";
    switch (legalForm) {
      case "ip":
        display += "ИП";
        break;
      case "organization":
        display += " Орг.";
        break;
      case "individual":
        display += "ФизЛицо";
        break;
      case "selfEmployed":
        display += "Самозанятый";
        break;
    }
    return display;
  };

  const mapChannelToDisplayName = (channel?: Entity<Channel>) => {
    if (channel?.values.displayName) {
      return channel.values.displayName;
    }

    const userChannelId = channel?.id;
    if (!userChannelId) return "Загрузка";

    const profile = profiles.get(userChannelId);
    if (!profile) return "Загрузка";
    const firstName = profile["firstName"];
    const lastName = profile["familyName"];
    const titleShort = profile["titleShort"];
    const titleFull = profile["titleFull"];

    return titleFull || titleShort || `${firstName} ${lastName}`;
  };


  // ====== FIlE UPLOAD SECTION ========
  const [attachments, setAttachments] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      console.log("clac");
    }
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles: FileList | null = event.target.files;
    if (selectedFiles) {
      // Now you can handle the selected files as needed
      console.log("Selected files:", selectedFiles);
      const files = Array.from(selectedFiles);
      console.log("files array:", files);
      setAttachments(files);
    }
  };

  const [loadedImages, setLoadedImages] = useState<
    Record<string, string | undefined>
  >({});

  const [lastConversion, setLastConversion] = useState<number>(0);
  const getImageUrlFromPath = useCallback(
    async (imagePath: string) => {
      if (loadedImages[imagePath]) {
        console.log("has image url, ");
        return;
      } else {
        setLoadedImages((li) => {
          li[imagePath] = undefined;
          return li;
        });

        const result = await storage.getDownloadURL(imagePath);

        console.log(`result ${result}`, result);

        setLoadedImages((li) => {
          li[imagePath] = result.url;
          return li;
        });
        setLastConversion(Date.now());
      }
    },
    [messages]
  );

  const getFileUrlFromPath = async (filePath: string) => {
    if (loadedImages[filePath]) {
      console.log("has image url, ");
      return;
    } else {
      setLoadedImages((li) => {
        li[filePath] = undefined;
        return li;
      });

      const result = await storage.getDownloadURL(filePath);

      console.log(`result ${result}`, result);

      setLoadedImages((li) => {
        li[filePath] = "file://" + result.url;
        return li;
      });
      setLastConversion(Date.now());
    }
  };

  useEffect(() => {
    if (selectedChannel == undefined) return;

    for (const message of (messages.get(selectedChannel!.id) || []).values()) {
      const messageAttachments = message.values.attachments;

      if (
        (messageAttachments || []).length > 0 &&
        !loadedImages[messageAttachments[0].path]
      ) {
        const attachment = messageAttachments[0];
        console.log("attacha", attachment);
        if (attachment && attachment.type && attachment["type"] == "image") {
          getImageUrlFromPath(message.values.attachments[0].path);
        } else {
          getFileUrlFromPath(message.values.attachments[0].path);
        }
      }
    }
  }, [lastUpdatedAt, lastConversion, selectedChannel]);

  const allowedAttachmentTypes = {
    document: ["pdf", "doc", "docx"],
    image: ["jpg", "png", "jpeg", "gif", "svg", "webp", "tiff", "bmp", "heic"],
  };

  const uploadAttachments = async (attachments: File[], userId: string) => {
    const uploadedAttachments = [];

    for (const file of attachments) {
      const extension = file.name.split(".").pop()!.toLowerCase();
      const attachmentType = Object.entries(allowedAttachmentTypes).find(
        ([key, value]) => value.includes(extension)
      ) || ["document", ["*"]];

      const uploadTask = await storage.uploadFile({
        file,
        path: `attachments/${userId}`,
        metadata: {
          contentType: file.type,
        },
      });

      console.log("uploaded task:", uploadTask);

      uploadedAttachments.push({
        name: file.name,
        path: uploadTask.path,
        type: attachmentType[0],
      });
    }

    return uploadedAttachments;
  };

  useEffect(() => {
    if (selectedChannel == undefined) return;

    const selectedConversation = selectedChannel
      ? {
          themeColor: "#6ea9d7",
          messages:
            selectedChannel == undefined
              ? [{ user: "", text: "a" }]
              : (messages.get(selectedChannel!.id) || []).map((message) => {
                  let imageUrl;
                  let fileLink;

                  const attachment = message.values.attachments?.[0];
                  const path = attachment && attachment.path;

                  const url = loadedImages[path];
                  let linkComponent;
                  if (url) {
                    if (url.startsWith("file://")) {
                      fileLink = url.split("file://")[1];
                      linkComponent = (
                        <Grid container direction={"column"}>
                          <Grid item>
                            <Typography variant="body2">
                              {" "}
                              Прикрепленный файл:
                            </Typography>
                          </Grid>

                          <Grid alignSelf={"end"} item>
                            <Typography variant="label" color="secondary">
                              {" "}
                              {attachment.name}{" "}
                            </Typography>
                          </Grid>

                          <Grid paddingTop={2} alignSelf={"end"}>
                            <a href={fileLink}>
                              <Button color="secondary" variant="outlined">
                                Скачать
                              </Button>
                            </a>
                          </Grid>
                        </Grid>
                      );
                    } else {
                      imageUrl = url;
                    }
                  }

                  const chatBubble: MessageType = {
                    user: {
                      id: message.values.support
                        ? "support"
                        : selectedChannel?.id || "id",
                      name: mapChannelToDisplayName(selectedChannel),
                      avatar: profiles.get(selectedChannel!.id)?.imageUrl,
                    },
                    text: ((fileLink && linkComponent) ||
                      message.values.message) as any,
                    created_at: message.values.timestamp.toUTCString(),
                    image: imageUrl ? imageUrl : undefined,
                  };

                  return chatBubble;
                }),
          // @ts-ignore
          header:
            selectedChannel?.id == undefined ? (
              ""
            ) : (
              <div
                onClick={() => {
                  const collection =
                    selectedChannel?.values.userRole == "applicant"
                      ? applicantsCollection
                      : selectedChannel?.values.legalForm == "organization"
                      ? clientsOrgCollection
                      : clientsIpCollection;
                  sideEntityController.open({
                    entityId: selectedChannel?.id,
                    path: selectedChannel!.values.profileRef.path,
                    // @ts-ignore
                    collection: collection,
                  });
                }}
              >
                {`${mapChannelToDisplayName(selectedChannel)}  (${mapUserForm(
                  selectedChannel?.values.legalForm
                )})`}{" "}
              </div>
            ),
          onAttachClick: () => {
            handleFileButtonClick();
          },
          currentUserId: "support",
          onSendMessage: send,
          onBack: () => {},
        }
      : undefined;

    console.log("triggered selected conversation");
    console.log(selectedConversation);
    setSelectedConversation(selectedConversation);
  }, [attachments, selectedChannel, lastUpdatedAt, lastConversion]);

  

  const send = useCallback(
    async (text: any) => {
      let message: any = {
        message: text,
        timestamp: new Date(),
        support: true,
      };

      let uploaded;
      if (attachments.length > 0) {
        console.log("upload attachments? ", attachments);
        uploaded = await uploadAttachments(attachments, selectedChannel!.id);
        message.attachments = uploaded;
      }

      try {
        await datasource.saveEntity({
          path: `channels/${selectedChannel!.id}/messages`,
          entityId: datasource.generateEntityId(
            `channels/${selectedChannel!.id}/messages`
          ),
          collection: messagesCollection,
          values: message,
          status: "new",
        });
        setAttachments([]);
      } catch (e) {
        console.error(e);
      }
    },
    [attachments.length, selectedChannel?.id]
  );

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        backgroundColor: themeMode.mode == "light" ? "#ffffff" : "#ffffff",
      }}
    >
      <MainContainer
        inbox={{
          themeColor: themeMode.mode == "light" ? "#EA572B" : "#EA572B",
          onScrollToBottom: () => {},
          showHeader: true,
          currentUserId: "support",
          conversations: channels.map<ConversationType>((channel) => {
            return {
              id: channel.id,
              title: `${mapChannelToDisplayName(channel)}  (${mapUserForm(
                channel.values.legalForm
              )})`,
              avatar: profiles.get(channel.id)?.imageUrl ?? "",
              lastMessage: {
                seen: false,
                text: channel.values.lastMessage?.message || undefined,
                user: {
                  id: channel.values.lastMessage?.support
                    ? "support"
                    : channel.id,
                  name: channel.values.lastMessage?.support
                    ? "Техподдержка"
                    : mapChannelToDisplayName(channel),
                  avatar: channel.values.lastMessage?.support
                    ? undefined
                    : profiles.get(channel.id)?.imageUrl,
                },
              },
            };
          }),
          loading: false,

          onConversationClick: (index) => {
            const channel = channels[index];
            // console.log(`selected channel ${JSON.stringify(channel)}`);
            setSelectedChannel(channel);
          },

          selectedConversationId: selectedChannel?.id,
        }}
        //@ts-ignore
        selectedConversation={
          !selectedChannel
            ? undefined
            : {
                ...selectedConversation,
                themeColor:
                  themeMode.mode == "light" ? "#EA572Baa" : "#EA572Baa",
              }
        }
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        multiple
        accept=".jpg,.jpeg,.png,.pdf,.heic,.docx,.doc,.xls,.csv"
        onChange={handleFileSelect}
      />

      <ProfilePickerView onChannelOpened={setSelectedChannel} />
    </div>
  );
}